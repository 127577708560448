<template>
  <div>
    <!--    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
               &#x1F310;<span class="sr-only">Search</span> 
            </template>
            <div v-for="locale in locales" :key="locale.key" :value="locale.key">
                <b-dropdown-item @click="onChange(locale.key)"> {{ locale.label }}</b-dropdown-item> 
            </div>
        </b-dropdown> -->
    <select id="locale" v-model="$i18n.locale">
      <option v-for="locale in locales" :key="locale.key" :value="locale.key">
        &#x1F310; <span class="sr-only"> {{ locale.label }}</span>
      </option>
    </select>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "locale-changer",
  data: () => ({
    locales: [
      {
        key: "es",
        label: "Español",
      },
      {
        key: "en",
        label: "English",
      },
    ],
  }),
  methods: {
    ...mapMutations("locales", {
      setLang: "SET_LANG",
    }),
    onChange(key) {
      this.$i18n.locale = key;
    },
  },
  watch: {
    "$i18n.locale": {
      handler(locale) {
        localStorage.setItem("locale", locale);
        this.setLang(locale);
      },
    },
  },
};
</script>
