/**
 * @author Fernando FH
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { instance } from "@/core/ApiConfig.js";
import { SECURE_ENDPOINT } from "@/core/AppConfiguration";

const RECEPTION_LIST_ALL_BY_BRANCH_OFFICE = SECURE_ENDPOINT + "repsvc/rep01";
const EDIT_GOVREQUISITION = SECURE_ENDPOINT + "rqgsvc/rqg03";
const CREATE_GOVREQUISITION = SECURE_ENDPOINT + "rqgsvc/rqg02";
const RECEPTION_ALL_ORDER_STOCK_BY_SHIPMENT = "repsvc/rep06";
const RECEPTION_ALL_BY_BRANCH_OFFICE = SECURE_ENDPOINT + "repsvc/rep07";
const RECEPTION_GET_WITH_DETAIL = SECURE_ENDPOINT + "repsvc/rep08";
const RECEPTION_ADD = SECURE_ENDPOINT + "repsvc/rep02";
const REQUISITION_LIST_BY_BRANCH_OFFICE = SECURE_ENDPOINT + "rqgsvc/rqg08";
export default class ReceptionRequest {

  static listAllByBranchOffice(idBranchOffcie) {
    return axios.post(
      RECEPTION_LIST_ALL_BY_BRANCH_OFFICE,
      idBranchOffcie
    );
  }

  static editGovRequesition(data) {
    return axios.post(EDIT_GOVREQUISITION, data)
  }

  static addGovRequesition(data) {
    return axios.post(CREATE_GOVREQUISITION, data);
  }

  static listAllOrderStockByShipment(idShipment) {
    return instance.post(
      RECEPTION_ALL_ORDER_STOCK_BY_SHIPMENT,
      idShipment
    );
  }

  static listAllByBranchOfficeHistory(idBranchOffcie) {
    return axios.post(
      RECEPTION_ALL_BY_BRANCH_OFFICE,
      idBranchOffcie
    );
  }

  static getDetailById(idReception) {
    return axios.post(RECEPTION_GET_WITH_DETAIL, idReception);
  }

  static async addRequesition(object) {
    const unwantedProps = ['productDescription', 'lot', 'fiscalFoundKey', 'location', 'expirationDate', 'prescribedQuantity', 'productKey', 'status', 'warehouseId'];
    object.orderStockList.forEach((stocks) => {
      unwantedProps.forEach((prop) => delete stocks[prop]);
    });
    return instance.post(RECEPTION_ADD, object);
  }

  static listAllRequisitions(object) {
    return axios.post(REQUISITION_LIST_BY_BRANCH_OFFICE, object);
  }
}
