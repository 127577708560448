export const DASHBOARD_DETAIL_START = (state) => {
  state.modelDetails = {
    shipmentCount: 0,
    prescriptionCount: 0,
    pieceCount: 0,
  };
  state.message = {};
};

export const DASHBOARD_DETAIL_SUCCESS = (state, val) => {
  state.modelDetails.shipmentCount = val.shipmentCount;
  state.modelDetails.prescriptionCount = val.prescriptionCount;
  state.modelDetails.pieceCount = val.pieceCount;
};

export const DASHBOARD_DETAIL_ERROR = (state, val) => {
  state.modelDetails = val;
};

export const DASHBOARD_DETAIL_CHART_SUCCESS = (state, val) => {
  state.modelDetailsChart.percentageList = val.percentageList;
  state.modelDetailsChart.minPercentaje.jurisdiction =
    val.minPercentaje.jurisdiction;
  state.modelDetailsChart.minPercentaje.percentage =
    val.minPercentaje.percentage;
  state.modelDetailsChart.maxPercentaje.jurisdiction =
    val.maxPercentaje.jurisdiction;
  state.modelDetailsChart.maxPercentaje.percentage =
    val.maxPercentaje.percentage;
  state.modelDetailsChart.generalAverage = val.generalAverage;
};

export const DASHBOARD_DETAIL_CHART_ERROR = (state) => {
  state.modelDetailsChart.percentageList = [];
  state.modelDetailsChart.minPercentaje.jurisdiction = "SIN VALORES";
  state.modelDetailsChart.minPercentaje.percentage = 0;
  state.modelDetailsChart.maxPercentaje.jurisdiction = "SIN VALORES";
  state.modelDetailsChart.maxPercentaje.percentage = 0;
  state.modelDetailsChart.generalAverage = 0;
};

export const DASHBOARD_DETAIL_TABLE_SUCCESS = (state, val) => {
  state.dataList = [...state.dataListBackup, ...val.dataList.sort(compareDates)];
};

function compareDates(a, b) {
  if (a.anio < b.anio) {
    return -1;
  } else if (a.anio > b.anio) {
    return 1;
  } else {
    const aMonthValue = monthValues[a.mes];
    const bMonthValue = monthValues[b.mes];

    if (aMonthValue < bMonthValue) {
      return -1;
    } else if (aMonthValue > bMonthValue) {
      return 1;
    } else {
      return 0;
    }
  }
}

const monthValues = {
  'Enero': 1,
  'Febrero': 2,
  'Marzo': 3,
  'Abril': 4,
  'Mayo': 5,
  'Junio': 6,
  'Julio': 7,
  'Agosto': 8,
  'Septiembre': 9,
  'Octubre': 10,
  'Noviembre': 11,
  'Diciembre': 12
}
