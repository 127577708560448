/**
 * @author Ivo Danic Garrido
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";

export default class FiscalFoundRequest {
  
  static findAllFiscalFounds() {
    return axios.post(ServicePath.GET_ALL_FINANCIAL_SOURCES);
  }
  static findAllFiscalFoundsAvailable(data) {
    return axios.post(ServicePath.GET_ALL_FINANCIAL_SOURCES_AVAILABLE,data);
  }

  static listAllFiscalFundByBranchOfficeForType(data) {
    return axios.post( ServicePath.FISCAL_FUND_LIST_ALL_BY_BRANCH_OFFICE_FOR_TYPE, data );
  }

  static findAllFiscalFoundsKey() {
    let fiscalFoundArray = [];
    this.findAllFiscalFounds().then(response => {
      let { success, message, data } = response.data.data;
      fiscalFoundArray = data;
    });
    return fiscalFoundArray;
  }

  static findAllFiscalFoundsKeyAvailable(data) {
    let fiscalFoundArray = [];
    this.findAllFiscalFoundsAvailable(data).then(response => {
      for (var [index, value] of Object.entries(response.data.data.objects)) {
        index;
        let fiscalFoundItem = {
          text: value.key,
          value: value.idFiscalfund
        };
        fiscalFoundArray.push(fiscalFoundItem);
      }
    });
    return fiscalFoundArray;
  }

  /*static listAllFiscalFundByBranchOfficeForTypeKey(data) {
    let fiscalFoundArray = [];
    this.listAllFiscalFundByBranchOfficeForType(data).then(response => {

      for (var [index, value] of Object.entries(response.data.data.data.objects)) {
        index;
        let fiscalFoundItem = {
          text: value.key,
          value: value.idFiscalfund
        };
        fiscalFoundArray.push(fiscalFoundItem);
      }
    });
    return fiscalFoundArray;
  }*/


}
