/**
 * @memberof store:Auth
 * @param {Boolean} val - Modifica la sesión en el state a true o false
 * @action session
 */
export const session = ({ commit }, payload) => {
  commit("SESSION_UPDATE", payload);
};

export const branchOffice = ({ commit }, payload) => {
  commit("UPDATE_VALUE", payload);
};

export const actionState = ({ commit }, payload) => {
  commit("UPDATE_ACTION", payload);
};

export const setMessage = ({ commit }, payload) => {
  commit("UPDATE_MESSAGES", payload);
};
