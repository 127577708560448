<template>
  <div>
    <div class="top-bar-alert">
      <!-- End Infobar Setting Sidebar -->
      <div class="topbar-mobile pl-0 pr-5">
        <div class="row align-items-center p-0">
          <div class="col-md-12 col-sm-12 pr-0 pl-0">
            <div class="mobile-togglebar p-0">
              <div class="infobar p-0">
                <div class="col-md-12 col-sm-12 p-0">
                  <ul class="list-inline p-0">
                    <li v-show="showBranchOffice" class="list-inline-item mr-2">
                      <b-dropdown
                        id="branch"
                        size="lg"
                        variant="none"
                        toggle-class="text-decoration-none p-0"
                        no-caret
                        v-if="originList.length > 1"
                      >
                        <template #button-content>
                          <span
                            class="
                            hand
                            mt
                            btn-b btn btn-rounded btn-outline-success
                          "
                          >
                            <i class="fa fa-sort-desc fa-pull-right"></i>
                            {{ $t("topbar.branch") }}
                          </span>
                        </template>
                        {{ originId }}
                        <b-dropdown-text
                          style="width: 325px"
                          class="text-center"
                        >
                          <v-autocomplete
                            dense
                            solo
                            hide-details
                            class="ma-0 p-0 branch"
                            :disabled="disabledView"
                            v-model="originId"
                            @change="updateItem"
                            :items="originList"
                            data-vv-name="select"
                          />
                        </b-dropdown-text>
                      </b-dropdown>
                    </li>
                    <!--------------------------------------------Producto no conforme --------------------------------------------------->
                    <li
                      class="list-inline-item mr-2"
                      v-if="
                        withPermissionName(permissions.NON_COMPLIANT_PRODUCT)
                      "
                    >
                      <router-link to="/productonoconforme/crear">
                        <button
                          style="text-decoration: none;"
                          type="button"
                          class="btn-b btn btn-rounded btn-outline-danger"
                        >
                          <span><i class="feather icon-database"></i></span>
                        </button>
                      </router-link>
                    </li>
                    <!--------------------------------------------Producto no conforme --------------------------------------------------->

                    <li class="list-inline-item">
                      <b-dropdown
                        id="info"
                        size="lg"
                        variant="none"
                        toggle-class="text-decoration-none p-0"
                        no-caret
                      >
                        <template #button-content>
                          <!--  <i class="fa fa-user-circle fa-2x green-user"></i> -->
                          <img
                            class="circle-topbar"
                            src="@/assets/images/user-svg/user_voice.svg"
                            fluid
                            alt="User imagen"
                          />

                          <i
                            class="fa fa fa-sort-desc fa-border green-user"
                          ></i>
                          <!--  <img class="circle-topbar" src="@/assets/images/users/img.jpeg" fluid alt="User imagen" /> -->
                        </template>
                        <b-dropdown-text style="width: 240px">
                          <div class="row pt-3">
                            <div class="col-md-12 text-center">
                              <div>
                                <!--  <i class="fa fa-user-circle fa-4x green-user"></i> -->
                                <img
                                  class="circle green-user"
                                  src="@/assets/images/user-svg/user_voice.svg"
                                  fluid
                                  alt="User imagen"
                                />
                              </div>
                            </div>
                            <div class="col-md-12 pb-0 text-center regular">
                              <span>{{ infoUser[0] }}</span>
                            </div>
                            <div class="col-md-12 pt-0 text-center regular">
                              <strong>{{ infoUser[1] }}</strong>
                            </div>

                            <div class="col-md-12 pt-0 pb-0 text-center">
                              <router-link to="/perfil">
                                <button class="btn-perfil">
                                  <i
                                    class="feather icon-user"
                                    aria-hidden="true"
                                  ></i>
                                  {{ $t("topbar.profile_management") }}
                                </button>
                              </router-link>
                            </div>
                            <div class="col-md-12 pt-0 text-center pb-0">
                              <ModalScheduleRegistration
                                v-if="
                                  withPermissionName(
                                    permissions.INCOMING_OUTGOING
                                  )
                                "
                              />
                            </div>
                          </div>
                        </b-dropdown-text>
                        <hr />
                        <!--<b-dropdown-text class="text-center">
                         <LocaleSwitcher />
                      </b-dropdown-text> -->
                        <b-dropdown-item class="text-center">
                          <button @click="logout" class="btn-logout">
                            {{ $t("topbar.logout") }}
                            <i class="ml-2 fa fa-sign-out"></i>
                          </button>
                        </b-dropdown-item>
                        <p class="text-center text--disabled font-italic pt-4">
                          {{ currentDate }}
                        </p>
                      </b-dropdown>
                    </li>
                    <li class="list-inline-item">
                      <div class="menubar">
                        <a class="menu-hamburger" @click="toggleMenu">
                          <img
                            src="@/assets/images/svg-icon/collapse.svg"
                            class="img-fluid menu-hamburger-collapse"
                            alt="collapse"
                          />
                          <img
                            src="@/assets/images/svg-icon/close.svg"
                            class="img-fluid menu-hamburger-close"
                            alt="close"
                          />
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Start Topbar -->
      <div class="topbar">
        <!-- Start row -->
        <div class="row align-items-center">
          <!-- Start col -->
          <div class="col-md-12 align-self-center">
            <div class="togglebar">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <div class="menubar">
                    <a class="menu-hamburger" @click="toggleMenu">
                      <img
                        src="@/assets/images/svg-icon/collapse.svg"
                        class="img-fluid menu-hamburger-collapse"
                        alt="collapse"
                      />
                      <img
                        src="@/assets/images/svg-icon/close.svg"
                        class="img-fluid menu-hamburger-close"
                        alt="close"
                      />
                    </a>
                  </div>
                </li>
                <li class="list-inline-item">
                  <div class="searchbar">
                    <div class="ml-5" v-show="showBranchOffice">
                      <v-autocomplete
                        dense
                        elevation="0"
                        solo
                        :no-data-text="$t('label.no_results')"
                        hide-details
                        class="branch-office"
                        :disabled="disabledView"
                        v-if="originList.length > 1"
                        v-model="originId"
                        @change="updateItem"
                        :items="originList"
                        data-vv-name="select"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="infobar p-0">
              <ul class="list-inline mb-0 p-0">
                <!--------------------------------------------Producto no conforme --------------------------------------------------->
                <li
                  class="list-inline-item mr-2"
                  v-if="withPermissionName(permissions.NON_COMPLIANT_PRODUCT)"
                >
                  <router-link to="/productonoconforme/crear">
                    <button
                      style="text-decoration: none;"
                      type="button"
                      class="btn-b btn btn-rounded btn-outline-danger"
                    >
                      <span>{{ $t("menu.non_compliant_product") }}</span>
                    </button>
                  </router-link>
                </li>
                <li class="list-inline-item mr-2">
                  <div>
                    <b-dropdown
                      id="dropdown-1"
                      size="lg"
                      variant="none"
                      toggle-class="text-decoration-none p-0"
                      no-caret
                    >
                      <template #button-content>
                        <img
                          class="circle-topbar"
                          src="@/assets/images/user-svg/user_voice.svg"
                          fluid
                          alt="User imagen"
                        />

                        <i class="fa fa fa-sort-desc fa-border green-user"></i>
                      </template>
                      <b-dropdown-text style="width: 240px">
                        <div class="row pt-3">
                          <div class="col-md-12 text-center">
                            <div>
                              <img
                                class="circle green-user"
                                src="@/assets/images/user-svg/user_voice.svg"
                                fluid
                                alt="User imagen"
                              />
                            </div>
                          </div>
                          <div class="col-md-12 pb-0 text-center regular">
                            <span>{{ infoUser[0] }}</span>
                          </div>
                          <div class="col-md-12 pt-0 text-center regular">
                            <strong>{{ infoUser[1] }}</strong>
                          </div>

                          <div class="col-md-12 pt-0 pb-0 text-center">
                            <router-link to="/perfil">
                              <button class="btn-perfil">
                                <i
                                  class="feather icon-user"
                                  aria-hidden="true"
                                ></i>
                                {{ $t("topbar.profile_management") }}
                              </button>
                            </router-link>
                          </div>
                          <div class="col-md-12 pt-0 text-center pb-0">
                            <ModalScheduleRegistration
                              v-if="
                                withPermissionName(
                                  permissions.INCOMING_OUTGOING
                                )
                              "
                            />
                          </div>
                        </div>
                      </b-dropdown-text>
                      <hr class="pt-0" />
                      <b-dropdown-text class="text-center">
                        <button @click="logout" class="btn-logout">
                          {{ $t("topbar.logout") }}
                          <i class="ml-1 fa fa-sign-out"></i>
                        </button>
                      </b-dropdown-text>

                      <p class="text-center text--disabled font-italic">
                        {{ currentDate }}
                      </p>

                      <!--   <b-dropdown-text class="text-center">
                        <LocaleSwitcher />
                      </b-dropdown-text> -->
                    </b-dropdown>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- End col -->
        </div>
        <!-- End row -->
      </div>
      <!-- End Topbar -->
    </div>
    <div>
      <AlertMessageTopBar />
    </div>
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import { mapState, mapMutations } from "vuex";
import ModalScheduleRegistration from "@/common/modal/ModalScheduleRegistration";
import LocaleSwitcher from "@/components/locales/LocaleSwitcher";
import AlertMessageTopBar from "@/common/message/AlertMessageTopBar.vue";
import moment from "moment";
export default {
  data() {
    return {
      currentDate: moment().format("LL"),
      status: false,
      originId: this.branchOffice,
      action: this.actionState,
      disabledView: this.actionState,
      url: [
        "/entradas/pedidos",
        "/dashboard",
        "/catalogos/sucursales",
        "/administracion/roles",
        "/catalogos/productos",
        "/catalogos/categorias",
        "/catalogos/proveedores",
        "/catalogos/jurisdicciones",
        "/catalogos/fuentesFinanciamiento",
        "/perfil",
        "/catalogos/cargo",
        "/catalogos/departamentos",
        "/configuracion/tableroControlAbasto",
        "/configuracion/configuracionTableroControl",
        "/productonoconforme/crear",
        "/productonoconforme/listar",
        "/cancelaciones",
        "/administracion/usuarios",
        "/auditoria/general",
        "/auditoria/movimientos-entradas-envios"
      ],
      showBranchOffice: false,
      dialog: false,
      type: null,
      permissions: PermissionConstants,
  /*     timerId: null */
    };
  },
  mounted() {
    /* this.startTimer();
    document.addEventListener('mousemove', this.resetTimer);
    document.addEventListener('keydown', this.resetTimer); */
    this.$root.$on("bv::dropdown::show", (bvEvent) => {
      if (bvEvent.componentId === "dropdown-2") {
        this.isDropdown2Visible = true;
      }
    });
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      if (bvEvent.componentId === "dropdown-2") {
        this.isDropdown2Visible = false;
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault();
      }
    });
  },
 /*  beforeDestroy() {
    document.removeEventListener('mousemove', this.resetTimer);
    document.removeEventListener('keydown', this.resetTimer);
  }, */
  methods: {
  /*   startTimer() {
      // Si el usuario está autenticado, establecer un temporizador para
      // cerrar la sesión después de 30 minutos de inactividad
      if (this.$session.exists()) {
        this.timerId = setTimeout(() => {
          this.logout();
        }, 10000); // 30 minutos en milisegundos
      }
    },
    resetTimer() {
      // Si el usuario realiza alguna acción, reiniciar el temporizador
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.startTimer();
      }
    }, */
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    ...mapMutations("global", {
      clearUser: "USER_NIVEL_ERROR",
    }),
    ...mapMutations("auth", {
      updateBrand: "UPDATE_VALUE",
      sessionUpdate: "SESSION_UPDATE",
      sessionExpired: "UPDATE_EXPIRED_SESSION",
      updateBranchOffice: "UPDATE_BRACH_OFFICCE",
      statusBranch: "STATUS_BRANCH_OFFICE",
    }),
    toggleMenu() {
      const body = window.document.querySelector(".vertical-layout");
      if (body != null || bodyOffice != null) {
        setTimeout(() => {
          body.classList.toggle("toggle-menu");
        }, 20);
      }
    },
    async logout() {
      this.$session.destroy();
      this.$router.push("/").catch(() => {});
      this.sessionUpdate(false);
      this.updateBranchOffice(null);
      this.clearUser();
      this.sessionExpired({
        isExpired: false,
        expiredTime: "",
      });
    },
    async updateItem(data) {
      this.statusBranch(true);
      const { branchOfficesSimple, mainBranch } = UtilFront.getDataUser();
      const branchOffice = branchOfficesSimple.find(
        (item) => item.value.idBranchoffice == data.idBranchoffice
      );
      if (branchOffice.status === undefined || branchOffice.status) {
        this.updateBrand(data);
      } else {
        const activeBranch = branchOfficesSimple.find(
          (item) =>
            item.value.idBranchoffice != data.idBranchoffice && item.status != 0
        );
        if (!activeBranch) {
          const message = this.getDisabledBranchMessage(
            branchOffice.text,
            mainBranch == data.idBranchoffice
          );
          this.statusBranch(false);
          await this.$SwalConfirm.fire({
            icon: "warning",
            title: "Aviso",
            showCancelButton: false,
            html: message,
          });
          this.logout();
          location.reload();
        } else {
          const message = this.getDisabledBranchMessage(
            branchOffice.text,
            mainBranch == data.idBranchoffice
          );
          this.$SwalConfirm.fire({
            icon: "warning",
            title: "Aviso",
            showCancelButton: false,
            html: message,
          });
          this.updateBrand(activeBranch.value);
          this.originId = this.branchOffice;
        }
      }
    },
    getDisabledBranchMessage(branchName, isMainBranch) {
      if (isMainBranch) {
        return `La sucursal <strong>${branchName}</strong> ha sido deshabilitada y no es posible realizar ninguna función con ella. Por favor, contacta a nuestro equipo de soporte, ya que esta sucursal está asignada como tu sucursal principal y necesitas asistencia para cambiarla o habilitarla nuevamente. Gracias por tu comprensión.`;
      }
      return `La sucursal <strong>${branchName}</strong> ha sido deshabilitada, por lo que no es posible realizar ninguna función con ella.`;
    },
    changeRoute() {
      if (!this.originId) {
        this.updateBrand(this.branchOffice);
        this.originId = this.branchOffice;
      }
      this.showBranchOffice = !this.url.includes(this.$route.path);
    },
  },
  computed: {
    ...mapState("auth", ["branchOffice", "actionState", "message"]),
    infoUser() {
      const { name, lastname, email } = UtilFront.getDataUser();
      let fullNameAndRol = `${name} ${lastname}`;
      return [fullNameAndRol, email];
    },
  },
 /*  mounted: function() {
     window.pages_close = this;

    localStorage.openpages = Date.now();
    var onLocalStorageEvent = function(e) {
      if (e.key == "openpages") {
        // Listen if anybody else is opening the same page!
        localStorage.page_available = Date.now();
      }
      if (e.key == "page_available") {
        alert("One more page already open");
        window.pages_close.logout();
        location.reload();
      }
    };
    window.addEventListener("storage", onLocalStorageEvent, false);
  }, */
  watch: {
    originId: function() {
      handler: (newVal) => {
        if (newVal) {
          this.updateBrand(newVal);
        }
      };
    },
    branchOffice() {
      this.idOrigin = this.branchOffice;
    },
    actionState() {
      this.disabledView = this.actionState > 1;
    },
    $route(to, from) {
      this.changeRoute();
    },
  },
  async created() {
    // Verifica el action actual
    this.action = this.actionState;
    this.disabledView = this.actionState > 1;
    const { branchOfficesSimple, mainBranch, email } = UtilFront.getDataUser();
    this.originList = branchOfficesSimple;

    const branch = { idBranchoffice: mainBranch };
    const selectedBranch =
      this.branchOffice || (mainBranch ? branch : this.originList[0].value);
    this.originId = selectedBranch;

    await this.updateItem(selectedBranch);

    //this.updateBrand(selectedBranch);
    if (email == "igl@scald.com.mx") {
      this.$session.destroy();
    }
  },
  components: {
    ModalScheduleRegistration,
    LocaleSwitcher,
    AlertMessageTopBar,
  },
};
</script>

<style lang="scss" scoped>
.fa-border {
  border: none !important;
}

b-dropdown-item {
  padding: 0px !important;
}

.top-bar-alert {
  padding-bottom: 110px;
}
.topbar {
  padding: 12px !important;
}

.v-text-field__details {
  padding: 0 !important;
}

.circle-topbar {
  height: 35px;
  width: 40px;
}

.green-user {
  color: #048e61;
}

.circle {
  height: 75px;
  width: 75px;
  border-radius: 150px;
}

.dropdown-menu .dropdown-item:hover {
  background: #fff;
}

.btn-perfil {
  border: 2px solid #048e61;
  background-color: #fff;
  border-radius: 100px;
  text-decoration: none;
  margin: 10px 0 0;
  outline: 0;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  color: #048e61;
}

.btn-perfil:hover {
  background-color: #048e61;
  color: #fff;
}

.btn-logout {
  border: 1px solid #f75679;
  background-color: #fff;
  border-radius: 10px;
  letter-spacing: 0.15px;
  padding: 7px 10px;
  color: #f75679;
  text-decoration: none;
}

.btn-logout:hover {
  background-color: #f75679;
  color: #fff;
}

.branch-office {
  min-width: 450px !important;
  padding: 0px;
}

@media (max-width: 1190px) {
  .branch-office {
    min-width: 400px !important;
  }
}

@media (max-width: 1140px) {
  .branch-office {
    min-width: 340px !important;
  }
}

@media (max-width: 1180px) {
  .branch-office {
    min-width: 250px !important;
  }
}

@media (max-width: 990px) {
  .branch-office {
    min-width: 210px !important;
  }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 0 0px 3px 1px rgb(0 0 0 / 20%), 0 0px 0px 0 rgb(0 0 0 / 14%),
    0 0px 0px 0 rgb(0 0 0 / 12%) !important;
}
</style>
