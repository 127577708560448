export default () => ({
  modelDetails: {
    shipmentCount: 0,
    prescriptionCount: 0,
    pieceCount: 0,
  },
  modelJurisdiction: {
    currentJurisdiction: "",
    minPercentage: {
      branchOffice: "SIN VALORES",
      percentageSupplier: 0,
    },
    maxPercentage: {
      branchOffice: "SIN VALORES",
      percentageSupplier: 0,
    },
    minPercentageHospital: {
      branchOffice: "SIN VALORES",
      percentageSupplier: 0,
    },
    maxPercentageHospital: {
      branchOffice: "SIN VALORES",
      percentageSupplier: 0,
    },
    generalAverage: {},
    currentJurisdictionID: "",
    listJurisdictions: [],
  },
});
