/**
 * @author Alan Saucedo
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";

import { instance } from "@/core/ApiConfig.js";

const MEDICAL_PRESCRIPTION_LIST_ALL_BY_BRANCH = "mpsvc/mp01";
const MEDICAL_PRESCRIPTION_LIST_INTRAHOSPITAL = "mpsvc/mp17";
export default class MedicalPrescriptionRequest {

    static addPrescription(data) {
      return axios.post(ServicePath.MEDICAL_PRESCRIPTION_ADD,data);
    }

    static validatePrescriptionKey(data) {
      return axios.post(ServicePath.MEDICAL_PRESCRIPTION_VALIDATE_PRESCRIPTION_KEY,data);
    }

    static finishMedicalPrescription(data) {
      return axios.post(ServicePath.MEDICAL_PRESCRIPTION_FINISH_PRESCRIPTION,data);
    }

    static updateValidated(data) {
      return axios.post(ServicePath.MEDICAL_PRESCRIPTION_UPDATE_VALIDATED,data);
    }

    static findAllPrescriptionByBranchOffice(data) {
        return instance.post(MEDICAL_PRESCRIPTION_LIST_ALL_BY_BRANCH,data);
    }

    static findAllPrescriptionByIntraHospital(data) {
      return instance.post(MEDICAL_PRESCRIPTION_LIST_INTRAHOSPITAL,data);
    }

    static listAllPrescriptionByProduct(data) {
      return axios.post( ServicePath.MEDICAL_PRESCRIPTION_BY_PRODUCT , data );
    }

    static listAllMedicalPrescriptionDenied(data) {
      return axios.post( ServicePath.MEDICAL_PRESCRIPTION_LIST_ALL_DENIED , data );
    }

    static addMedicalPrescriptionDenied(data) {
      return axios.post( ServicePath.MEDICAL_PRESCRIPTION_ADD_DENIED , data );
    }

    static deleteMedicalPrescriptionDenied(data) {
      return axios.post( ServicePath.MEDICAL_PRESCRIPTION_DELETE_DENIED , data );
    }

    static validateMedicalPrescriptionAndOrder(data) {
      return axios.post( ServicePath.MEDICAL_PRESCRIPTION_VALIDATE_ORDER , data );
    }

    static getMedicalPrescriptionById(data) {
      return axios.post( ServicePath.MEDICAL_PRESCRIPTION_FIND_BY_ID , data );
    }

    static downloadPrescription(data) {
      return axios.post( ServicePath.MEDICAL_PRESCRIPTION_DOWNLOAD_PRESCRIPTIOM , data );
    }

    static saveSurvey(data) {
      return axios.post( ServicePath.MEDICAL_PRESCRIPTION_SAVE_SURVEY , data );
    }

    static findAllPrescriptionByBranchOfficeCancelations(data) {
      return axios.post(ServicePath.MEDICAL_PRESCRIPTION_LIST_ALL_BY_BRANCH_CANCELATIONS,data);
    }

}