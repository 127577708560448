import { UtilFront } from "@/core";
import { SwalConfirm } from "@/core/SwalAlert";

/**
 * @memberof store:Auth
 * @param {Boolean} val - Modifica la sesión en el state a true o false
 * @mutation SESSION_UPDATE
 */
export const SESSION_UPDATE = (state, val) => {
  state.isAuthenticated = val;
};

/**
 * @memberof store:Auth
 * @param {Number} val - Recibe el valor de la sucursal seleccionada en el topBar
 * @mutation UPDATE_VALUE
 */
export const UPDATE_VALUE = async (state, val) => {
  state.branchOffice = val;
};

export const STATUS_BRANCH_OFFICE = async (state, val) => {
  state.branchOfficeActive = val;
};


/**
 * @memberof store:Auth
 * @mutation UPDATE_ACTION
 * @param {Number} val - Recibe un valor numerico para mostrar diferentes vista en la aplicacion, se mencionan algunos ejemplos
 * @param  {Number} val.1 Vista principal
 * @param  {Number} val.2 Registro de información
 * @param  {Number} val.3 Modificacion de información
 */
export const UPDATE_ACTION = (state, val) => {
  state.actionState = val;
};

/**
 * @memberof store:Auth
 * @mutation UPDATE_EXPIRED_SESSION
 * @desc  Valida la sesión para que expire en un tiempo determinado - FUNCION AUN NO IMPLEMENTADA
 * @param  {Object} val
 * @param  {Boolean} val.isExpired Valor para validar la sesión
 * @param  {String} val.expiredTime Tiempo de expiración de la sesión
 */
export const UPDATE_EXPIRED_SESSION = (state, val) => {
  state.isExpired = val.isExpired;
  state.expiredTime = val.expiredTime;
};

/**
 * @memberof store:Auth
 * @mutation UPDATE_BRACH_OFFICCE
 * @desc Limpia los objecto de sucursale al momento de cerrar sesión
 */
export const UPDATE_BRACH_OFFICCE = (state) => {
  state.statusBranchSelect = [];
  state.branchOffice = null;
};

export const UPDATE_MESSAGES = (state, val) => {
  state.message.type = val.type;
  state.message.message = val.message;
  state.message.alert = val.alert;
  state.message.styleTypeAlert = val.styleTypeAlert;
  state.message.spam = val.spam;
};
