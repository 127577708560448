/**
 * @author Fernando FH
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath,UtilFront } from "@/core";

export default class JurisdictionRequest {
  static listAll() {
      return axios.post(ServicePath.JURISDICTION_LIST_ALL);
  }
  static add(object) {
      return axios.post(ServicePath.JURISDICTION_ADD, object);
  }
  static update(object) {
      return axios.post(ServicePath.JURISDICTION_UPDATE,  object);
  }
}