/**
 * @author Alan Saucedo
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";
import { base64StringToBlob } from 'blob-util';

export default class PDFRequest {

    static responseTypeV = {responseType: 'blob'};

    /* Genera el archivo */
    static downloadBase64( response, fileName ) {
      const linkSource = `data:application/vnd.ms-excel;base64,${response.entity}`;
      const downloadLink = document.createElement("a");

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }


    static downloadBase64PDF( data, file ) {
      const url = URL.createObjectURL( base64StringToBlob( data.entity , "application/pdf" ) );
      window.open(url);
    }


}