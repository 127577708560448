/**
 * @author Ivo Danic Garrido
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";

export default class LoginRequest {
  static authenticate(data) {
    return axios.post(ServicePath.SIGNIN_USER, data);
  }

  static authenticateUser(data) {
    return this.authenticate(data);
    // return axios.post(ServicePath.SIGNIN_USER, data);

    // let responseData = "";
    // this.authenticate(data).then(response => {
    //   if (response.data.code == 200) {
    //     responseData = response.data;
    //     localStorage.setItem("name", response.data.data.name);
    //     localStorage.setItem("lastname", response.data.data.lastname);
    //     localStorage.setItem("branchOffice", response.data.data.branchOffices);
    //     localStorage.setItem("permissions", response.data.data.permissions);
    //   } else {
    //     responseData = { success: false };
    //   }
    // });
    // return responseData;
  }

  static getUserBranchesLocal() {
    if (localStorage.getItem("branchOffice")) {
      this.responseData = JSON.parse(localStorage.getItem("branchOffice"));
    } else {
      localStorage.clear();
    }
    return this.responseData;
  }

  static getUserPermissionsLocal() {
    if (localStorage.getItem("permissions")) {
      this.responseData = JSON.parse(localStorage.getItem("permissions"));
    } else {
      localStorage.clear();
    }
    return this.responseData;
  }

  static postScheduleRegistration(data) {
    return axios.post(ServicePath.SCHEDULE_REGISTRATION_INPUT_OR_OUTPUT, data);
  }

  static getMessage() {
    return axios.post(ServicePath.GET_MESSAGE);
  }

  static getAttempts(data) {
    return axios.post(ServicePath.SIGNIN_USER_ATTEMPTS, data);
  }
}
