export const getSumProductsDispensing = (state) => {
  return state.dataList
    .map((item) => item.quantityS)
    .reduce((prev, curr) => prev + curr, 0);
};

export const getSumMedicalPrescription = (state) => {
  return state.dataList
    .map((item) => item.quantityD)
    .reduce((prev, curr) => prev + curr, 0);
};
