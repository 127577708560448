export default () => ({
  modelDetails: {
    shipmentCount: 0,
    prescriptionCount: 0,
    pieceCount: 0,
  },
  modelDetailsChart: {
    percentageList: [],
    minPercentaje: {
      jurisdiction: "SIN VALORES",
      percentage: 0,
    },
    maxPercentaje: {
      jurisdiction: "SIN VALORES",
      percentage: 0,
    },
    generalAverage: 0,
  },
  dataList: [
    {
      mes: "Enero",
      anio: 2021,
      quantityS: 42940,
      quantityD: 176488,
    },
    {
      mes: "Febrero",
      anio: 2021,
      quantityS: 56432,
      quantityD: 246557,
    },
    {
      mes: "Marzo",
      anio: 2021,
      quantityS: 93565,
      quantityD: 416965,
    },
    {
      mes: "Abril",
      anio: 2021,
      quantityS: 130036,
      quantityD: 542519,
    },
    {
      mes: "Mayo",
      anio: 2021,
      quantityS: 128999,
      quantityD: 686770,
    },
    {
      mes: "Junio",
      anio: 2021,
      quantityS: 142559,
      quantityD: 707503,
    },
    {
      mes: "Julio",
      anio: 2021,
      quantityS: 144750,
      quantityD: 698556,
    },
    {
      mes: "Agosto",
      anio: 2021,
      quantityS: 150922,
      quantityD: 728964,
    },
    {
      mes: "Septiembre",
      anio: 2021,
      quantityS: 128868,
      quantityD: 637672,
    },
    {
      mes: "Octubre",
      anio: 2021,
      quantityS: 104582,
      quantityD: 466051,
    },
    {
      mes: "Noviembre",
      anio: 2021,
      quantityS: 134532,
      quantityD: 657215,
    },
    {
      mes: "Diciembre",
      anio: 2021,
      quantityS: 96520,
      quantityD: 459356,
    },
    {
      mes: "Enero",
      anio: 2022,
      quantityS: 78415,
      quantityD: 345517,
    },
    {
      mes: "Febrero",
      anio: 2022,
      quantityS: 94242,
      quantityD: 395076,
    },
  ],
  dataListBackup: [
    {
      mes: "Enero",
      anio: 2021,
      quantityS: 42940,
      quantityD: 176488,
    },
    {
      mes: "Febrero",
      anio: 2021,
      quantityS: 56432,
      quantityD: 246557,
    },
    {
      mes: "Marzo",
      anio: 2021,
      quantityS: 93565,
      quantityD: 416965,
    },
    {
      mes: "Abril",
      anio: 2021,
      quantityS: 130036,
      quantityD: 542519,
    },
    {
      mes: "Mayo",
      anio: 2021,
      quantityS: 128999,
      quantityD: 686770,
    },
    {
      mes: "Junio",
      anio: 2021,
      quantityS: 142559,
      quantityD: 707503,
    },
    {
      mes: "Julio",
      anio: 2021,
      quantityS: 144750,
      quantityD: 698556,
    },
    {
      mes: "Agosto",
      anio: 2021,
      quantityS: 150922,
      quantityD: 728964,
    },
    {
      mes: "Septiembre",
      anio: 2021,
      quantityS: 128868,
      quantityD: 637672,
    },
    {
      mes: "Octubre",
      anio: 2021,
      quantityS: 104582,
      quantityD: 466051,
    },
    {
      mes: "Noviembre",
      anio: 2021,
      quantityS: 134532,
      quantityD: 657215,
    },
    {
      mes: "Diciembre",
      anio: 2021,
      quantityS: 96520,
      quantityD: 459356,
    },
    {
      mes: "Enero",
      anio: 2022,
      quantityS: 78415,
      quantityD: 345517,
    },
    {
      mes: "Febrero",
      anio: 2022,
      quantityS: 94242,
      quantityD: 395076,
    },
  ],
  sumProductsDispensing: 0,
  sumMedicalPrescription: 0
});
