import { DashboardRequest } from "@/other/core";
import moment from "moment";

/* Jurisdiction - ACTIONS */
export const dashboardDetailJurisdictionAction = async (
  { commit },
  payload
) => {
  if (payload == null) {
    commit("DASHBOARD_JURISDICTION_DETAIL_ERROR");
    commit("DASHBOARD_CHART_JURISDICTION_DETAIL_ERROR");
    commit("DASHBOARD_BY_JURISDICTION_TABLE_ERROR");
    return;
  }
  commit("global/IS_LOADING", true, { root: true });
  await DashboardRequest.dashboardDetail(payload)
    .then((response) => {
      let { data } = response;
      let val = {
        shipmentCount: data.receptions,
        prescriptionCount: data.medicalPrescriptions,
        pieceCount: data.dispensingProducts,
      };
      commit("DASHBOARD_JURISDICTION_DETAIL_SUCCESS", val);
      commit("global/IS_LOADING", false, { root: true });
    })
    .catch((error) => {
      commit("DASHBOARD_JURISDICTION_DETAIL_ERROR");
      commit("global/IS_LOADING", false, { root: true });
      console.log(error);
    });
};

export const dashboardJurisdictionChartAction = async ({ commit }, payload) => {
  commit("global/IS_LOADING", true, { root: true });
  await DashboardRequest.dashboardDetailJurisdiction(payload)
    .then((response) => {
      let { data } = response;
      let val = {
        generalAverage: data.generalAverage
          ? data.generalAverage
          : { average: 0, jurisdictionName: payload.jurisdictionName },
        maxPercentage:
          data.maxPercentage == undefined
            ? {
                name: "SIN CENTROS DE SALUD",
                percentageSupplier: 0,
              }
            : data.maxPercentage,
        minPercentage:
          data.minPercentage == undefined
            ? {
                name: "SIN CENTROS DE SALUD",
                percentageSupplier: 0,
              }
            : data.minPercentage,
        minPercentageHospital:
          data.minPercentageHospital == undefined
            ? {
                name: "SIN HOSPITALES",
                percentageSupplier: 0,
              }
            : data.minPercentageHospital,
        maxPercentageHospital:
          data.maxPercentageHospital == undefined
            ? {
                name: "SIN HOSPITALES",
                percentageSupplier: 0,
              }
            : data.maxPercentageHospital,
        currentJurisdictionID: payload.jurisdictionId,
        currentJurisdiction: data.generalAverage?.jurisdictionName
          ? data.generalAverage.jurisdictionName
          : payload.jurisdictionName,
      };
      commit("DASHBOARD_CHART_JURISDICTION_DETAIL_SUCCESS", val);
      commit(
        "DASHBOARD_BY_JURISDICTION_TABLE_SUCCESS",
        data.branchOfficesDashboard ? data.branchOfficesDashboard : []
      );
      commit("global/TAP_SHOW", 1, { root: true });
      commit("global/IS_LOADING", false, { root: true });
    })
    .catch((error) => {
      commit("DASHBOARD_CHART_JURISDICTION_DETAIL_ERROR");
      commit("DASHBOARD_BY_JURISDICTION_TABLE_ERROR");
      commit("DASHBOARD_JURISDICTION_DETAIL_ERROR");
      commit("global/IS_LOADING", false, { root: true });
      console.log(error);
    });
};
