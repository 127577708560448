/**
 * @memberof store:Auth
 * @namespaced
 * @desc State de Auth para ver mas detalle dar click en store/modules/auth/state.js
 */
export  default  ()  => ({
    isLoading: false,
    isAuthenticated: false,
    branchOffice: null,
    listBranchOffice: [],
    statusBranchSelect: false,
    actionState: 1,
    isExpired: false,
    expiredTime: "",
    branchOfficeActive: true,
    message: {
      type: "",
      message: "",
      alert: false,
      styleTypeAlert: "",
      spam: false
    },
  });
  