<template>
  <div class="leftbar">
    <!-- Start Sidebar -->
    <div class="sidebar">
      <!-- Start Logobar -->
      <div class="logobar">
        <a class="logo logo-large"
          ><img src="@/assets/images/logo_.svg" class="img-fluid" alt="logo" />
        </a>
        <a class="logo logo-small">
          <img src="@/assets/images/icon.png" class="img-fluid" alt="logo" />
        </a>
      </div>
      <!-- End Logobar -->
      <!-- Start Navigationbar -->
      <div class="navigationbar">
        <ul class="vertical-menu">
          <li :class="{ active: isRouteActive('Dashboard') }">
            <router-link to="/dashboard">
              <i class="feather icon-pie-chart"></i>
              <span>{{ $t("menu.dashboard") }}</span>
              <i class="feather icon-chevron-right pull-right"></i>
            </router-link>
          </li>
          <!----------------------------------------------------------- TRAZABILIDAD ----------------------------------------------------------->
          <li
            v-if="withPermissions(permissions.TRACEABILITY)"
            :class="{ active: isRouteActive('Trazabilidad') }"
          >
            <router-link to="/trazabilidad">
              <i class="feather icon-folder" aria-hidden="true"></i>
              <span>{{ $t("menu.traceability") }}</span>
              <i class="feather icon-chevron-right pull-right"></i>
            </router-link>
          </li>
          <!----------------------------------------------------------- AUDITORÍA ----------------------------------------------------------->
          <li
            v-if="withPermissions(permissions.AUDIENCE)"
            :class="{active: showAudit || isRouteActive('Auditoria') }"
          >
            <a @click="showAudit = !showAudit" style="cursor: pointer">
              <i class="feather icon-check-square"></i>
              <span>Auditoría</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showAudit">
              <li>
                <router-link
                  to="/auditoria/general"
                  v-if="withPermissions(permissions.AUDIENCE)"
                >
                  Movimientos generales
                </router-link>
                <router-link
                  to="/auditoria/movimientos-entradas-envios"
                  v-if="withPermissions(permissions.AUDIENCE)"
                >
                  Movimientos de entradas y salidas
                </router-link>
              </li>
            </ul>
          </li>
          <!----------------------------------------------------------- ENTRADAS ----------------------------------------------------------->
          <li
            :class="{ active: showRemmision || isSubRouteActive('/entradas') }"
            v-if="withPermissions(permissions.REMISSION)"
          >
            <a @click="showRemmision = !showRemmision" style="cursor: pointer">
              <i class="feather icon-package"></i>
              <span>{{ $t("menu.remission") }}</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showRemmision">
              <li>
                <router-link
                  to="/entradas/pedidos"
                  v-if="withPermissions(permissions.REQUISITION)"
                >
                  {{ $t("menu.requisition") }}
                </router-link>
                <router-link
                  to="/entradas/proveedor"
                  v-if="withPermissions(permissions.REMISSION_BY_SUPPLIER)"
                >
                  {{ $t("menu.remission_by_supplier") }}
                </router-link>
                <router-link
                  to="/entradas/traspaso"
                  v-if="withPermissions(permissions.REMISSION_BY_TRANSFER)"
                >
                  {{ $t("menu.remission_by_transfer") }}
                </router-link>
              </li>
            </ul>
          </li>
          <!----------------------------------------------------------- Almacén ----------------------------------------------------------->
          <li
            :class="{
              active:
                showWareHouse ||
                isSubRouteActive('/almacen') ||
                isSubRouteActive('/caducados'),
            }"
            v-if="withPermissions(permissions.WAREHOUSE)"
          >
            <a @click="showWareHouse = !showWareHouse" style="cursor: pointer">
              <i class="feather icon-grid"></i>
              <span>{{ $t("menu.stock") }}</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showWareHouse">
              <li>
                <router-link
                  to="/almacen"
                  v-if="withPermissions(permissions.INVENTORY_DETAIL)"
                >
                  {{ $t("menu.detail_stock") }}
                </router-link>

                <router-link
                  to="/almacen/productonoconforme"
                  v-if="
                    withPermissions(permissions.INVENTORY_NON_COMPLIANT_PRODUCT)
                  "
                >
                  {{ $t("menu.non_compliant_product") }}
                </router-link>
                <router-link
                  to="/caducados"
                  v-if="withPermissions(permissions.INVENTORY_DETAIL_EXPIRED)"
                >
                  {{ $t("menu.expired_stock") }}
                </router-link>
                <router-link
                  to="/almacen/ciclico"
                  v-if="withPermissions(permissions.CYCLIC_INVENTORY)"
                >
                  <i class="ti-alarm-clock mr-1"></i>
                  {{ $t("menu.cyclical_inventory") }}
                </router-link>
              </li>
            </ul>
          </li>
          <!----------------------------------------------------------- Solicitudes ----------------------------------------------------------->

          <li
            :class="{
              active:
                showShipmentsRequest ||
                isSubRouteActive('/solicitudes') ||
                isSubRouteActive('/solicitudes/autorizar') ||
                isSubRouteActive('/solicitudes/validar'),
            }"
            v-if="withPermissions(permissions.PRE_REQUEST)"
          >
            <a
              @click="showShipmentsRequest = !showShipmentsRequest"
              style="cursor: pointer"
            >
              <i class="feather icon-clipboard"></i>
              <span> Solicitudes</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul
              class="vertical-submenu in menu-open"
              v-if="showShipmentsRequest"
            >
              <li>
                <router-link
                  to="/solicitudes"
                  v-if="withPermissions(permissions.PRE_REQUEST_APPLICATION)"
                  >{{ $t("menu.shipment_request") }}</router-link
                >
                <router-link
                  to="/solicitudes/validar"
                  v-if="withPermissions(permissions.PRE_REQUEST_VALIDATOR)"
                  >Solicitudes por validar</router-link
                >
                <router-link
                  to="/solicitudes/autorizar"
                  v-if="withPermissions(permissions.PRE_REQUEST_AUTHORIZER)"
                  >Solicitudes por autorizar</router-link
                >
              </li>
            </ul>
          </li>
          <!----------------------------------------------------------- Salidas ----------------------------------------------------------->
          <li
            :class="{
              active:
                showOrders ||
                isSubRouteActive('/salidas') ||
                isSubRouteActive('/salidas/motivo'),
            }"
            v-if="withPermissions(permissions.STARTING_ORDER)"
          >
            <a @click="showOrders = !showOrders" style="cursor: pointer">
              <i class="feather icon-file-text"></i>
              <span>{{ $t("menu.starting_order") }}</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showOrders">
              <li>
                <router-link
                  to="/salidas"
                  v-if="withPermissions(permissions.STARTING_ORDER_GENERAL)"
                  >{{ $t("menu.starting_order_generals") }}</router-link
                >
                <router-link
                  to="/salidas/motivo"
                  v-if="withPermissions(permissions.STARTING_ORDER_BY_REASON)"
                  >{{ $t("menu.starting_order_reason") }}</router-link
                >
              </li>
            </ul>
          </li>
          <!----------------------------------------------------------- Picking ----------------------------------------------------------->
          <li
            :class="{ active: showPicking || isSubRouteActive('/picking') }"
            v-if="
              withPermissions(permissions.PICKING_DETAIL) ||
                withPermissions(permissions.PICKING_MOVEMENTS)
            "
          >
            <a @click="showPicking = !showPicking" style="cursor: pointer">
              <i class="feather icon-shopping-cart"></i
              ><span>{{ $t("menu.picking") }}</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showPicking">
              <li>
                <router-link
                  to="/picking"
                  v-if="withPermissions(permissions.PICKING_DETAIL)"
                >
                  {{ $t("menu.picking_detail") }}
                </router-link>
                <router-link
                  to="/picking/movimiento"
                  v-if="withPermissions(permissions.PICKING_MOVEMENTS)"
                >
                  {{ $t("menu.picking_movements") }}
                </router-link>
              </li>
            </ul>
          </li>
          <!----------------------------------------------------------- Envios ----------------------------------------------------------->
          <li
            :class="{ active: showShipments || isSubRouteActive('/envios') }"
            v-if="withPermissions(permissions.SHIPMENTS)"
          >
            <a @click="showShipments = !showShipments" style="cursor: pointer">
              <i class="feather icon-truck"></i
              ><span>{{ $t("menu.shipments") }}</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showShipments">
              <li>
                <router-link
                  to="/envios"
                  v-if="withPermissions(permissions.SHIPMENT_DETAIL)"
                >
                  {{ $t("menu.shipments_detail") }}
                </router-link>
                <router-link
                  to="/envios/historico"
                  v-if="withPermissions(permissions.HISTORY_OF_SHIPMENTS)"
                >
                  {{ $t("menu.shipments_historical") }}
                </router-link>
              </li>
            </ul>
          </li>
          <!----------------------------------------------------------- Recepción ----------------------------------------------------------->
          <li
            :class="{
              active: showReception || isSubRouteActive('/recepcion/'),
            }"
            v-if="withPermissions(permissions.RECEPTION)"
          >
            <a @click="showReception = !showReception" style="cursor: pointer">
              <i class="feather icon-archive"></i>
              <span> {{ $t("menu.reception") }}</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showReception">
              <li>
                <router-link
                  v-if="withPermissions(permissions.PENDING)"
                  to="/recepcion/pendientes"
                >
                  {{ $t("menu.pending") }}
                </router-link>
                <router-link
                  v-if="withPermissions(permissions.HISTORICAL)"
                  to="/recepcion/historico"
                >
                  {{ $t("menu.historical") }}
                </router-link>
              </li>
            </ul>
          </li>
          <!----------------------------------------------------------- Incidencias ----------------------------------------------------------->

          <li
            :class="{
              active: showIncidence || isSubRouteActive('/incidencia/'),
            }"
            v-if="
              withPermissions(permissions.PERFORMED) ||
                withPermissions(permissions.STARTERS)
            "
          >
            <a @click="showIncidence = !showIncidence" style="cursor: pointer">
              <i class="feather icon-alert-triangle"></i>
              <span> {{ $t("menu.incidents") }}</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showIncidence">
              <li>
                <router-link
                  v-if="withPermissions(permissions.PERFORMED)"
                  to="/incidencia/realizadas"
                >
                  {{ $t("menu.performed") }}
                </router-link>
                <router-link
                  v-if="withPermissions(permissions.STARTERS)"
                  to="/incidencia/entrantes"
                >
                  {{ $t("menu.starters") }}
                </router-link>
              </li>
            </ul>
          </li>

          <!-------------------------------------------------------- Dispensacion --------------------------------------------------------->
          <li
            :class="{
              active:
                showDispensing ||
                isSubRouteActive('/dispensacion') ||
                isSubRouteActive('/configuracion/doctores') ||
                isSubRouteActive('/configuracion/empleados'),
            }"
            v-if="
              withPermissions('DISPENSING') ||
                withPermissions('STAFF') ||
                withPermissions('DOCTORS')
            "
          >
            <a
              @click="showDispensing = !showDispensing"
              style="cursor: pointer"
            >
              <i class="feather icon-file-plus"></i>
              <span>{{ $t("menu.dispensing") }}</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showDispensing">
              <li>
                <router-link
                  to="/configuracion/doctores"
                  v-if="withPermissions('DOCTORS')"
                >
                  {{ $t("menu.doctors") }}
                </router-link>

                <router-link
                  v-if="withPermissions('DISPENSING')"
                  to="/dispensacion"
                >
                  {{ $t("menu.dispense_prescription") }}
                </router-link>

                <router-link
                  v-if="withPermissions('STAFF')"
                  to="/configuracion/empleados"
                >
                  {{ $t("menu.personnel") }}
                </router-link>

                <router-link
                  v-if="
                    withPermissions('PRESCRIPTIONS-INTRAHOSPITAL-DISPENSING')
                  "
                  to="/dispensacion/intrahospitalaria"
                >
                  <!-- {{ $t("menu.dispense_prescription") }} -->
                  Dispensacion intrahospitalaria
                </router-link>
              </li>
            </ul>
          </li>
          <!-------------------------------------------------------- Cancelaciones --------------------------------------------------------->

          <li
            v-if="withPermissions('CANCELLATIONS')"
            :class="{
              active: isRouteActive('/cancelaciones'),
            }"
          >
            <router-link to="/cancelaciones">
              <i class="feather icon-slash"></i>
              <span>{{ $t("menu.cancellations") }}</span
              ><i class="feather icon-chevron-right pull-right"></i>
            </router-link>
          </li>

          <!-------------------------------------------------------- Administración --------------------------------------------------------->

          <li
            :class="{
              active: showAdmin || isSubRouteActive('/administracion/'),
            }"
            v-if="withPermissions('ADMINISTRATION')"
          >
            <a @click="showAdmin = !showAdmin" style="cursor: pointer">
              <i class="feather icon-users"></i
              ><span> {{ $t("menu.administration") }}</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showAdmin">
              <li>
                <router-link
                  v-if="withPermissions('USERS')"
                  to="/administracion/usuarios"
                >
                  {{ $t("menu.users") }}
                </router-link>
                <router-link
                  v-if="withPermissions('ROLES')"
                  to="/administracion/roles"
                >
                  {{ $t("menu.roles") }}
                </router-link>
              </li>
            </ul>
          </li>

          <!----------------------------------------------------------- PRODUCTO NO CONFORME ----------------------------------------------------------->
          <li v-if="withPermissions(permissions.NON_COMPLIANT_PRODUCT)">
            <router-link
              :to="{
                name: 'NON-COMPLIANT-PRODUCT',
                params: {
                  action: 'listar',
                },
              }"
            >
              <i class="feather icon-alert-circle" aria-hidden="true"></i>
              <span>No conforme</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </router-link>
          </li>
          <!-------------------------------------------------------- Catalogos --------------------------------------------------------->
          <!-- isSubRouteActive('/configuracion/jurisdicciones') || -->
          <li
            :class="{
              active:
                showCatalogue ||
                isSubRouteActive('/configuracion/productos') ||
                isSubRouteActive('/configuracion/sucursales') ||
                isSubRouteActive('/configuracion/categorias') ||
                isSubRouteActive('/configuracion/proveedores') ||
                isSubRouteActive(
                  '/configuracion/fuentesFinanciamiento' ||
                    isSubRouteActive('/configuracion/cargo') ||
                    isSubRouteActive('/configuracion/departamentos')
                ),
            }"
            v-if="withPermissions('CATALOGS')"
          >
            <a @click="showCatalogue = !showCatalogue" style="cursor: pointer">
              <i class="feather icon-database"></i>
              <span> {{ $t("menu.catalogs") }}</span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showCatalogue">
              <li>
                <router-link
                  v-if="withPermissions('BRANCH-OFFICES')"
                  to="/catalogos/sucursales"
                >
                  {{ $t("menu.branch_offices") }}
                </router-link>
                <router-link
                  v-if="withPermissions('POSITIONS')"
                  to="/catalogos/cargo"
                >
                  {{ $t("menu.position") }}
                </router-link>
                <router-link
                  v-if="withPermissions('CATEGORIESSS')"
                  to="/catalogos/categorias"
                >
                  {{ $t("menu.categories") }}
                </router-link>
                <router-link
                  v-if="withPermissions('DEPARTMENTS')"
                  to="/catalogos/departamentos"
                >
                  {{ $t("menu.departments") }}
                </router-link>
                <router-link
                  v-if="withPermissions('FINANCING')"
                  to="/catalogos/fuentesFinanciamiento"
                >
                  {{ $t("menu.financing") }}
                </router-link>
                <!-- <router-link
                  v-if="withPermissions('JURISDICTIONS')"
                  to="/catalogos/jurisdicciones"
                >
                  {{ $t("menu.jurisdictions") }}
                </router-link> -->
                <router-link
                  v-if="withPermissions('PRODUCTS')"
                  to="/catalogos/productos"
                >
                  {{ $t("menu.products") }}
                </router-link>
                <router-link
                  v-if="withPermissions('SUPPLIERS')"
                  to="/catalogos/proveedores"
                >
                  {{ $t("menu.suppliers") }}
                </router-link>
                <router-link
                  v-if="withPermissions('DISTRIBUTION-BOARD')"
                  to="/catalogos/cuadroDistribucion"
                >
                  Cuadro de distribución
                </router-link>
              </li>
            </ul>
          </li>

          <!-------------------------------------------------------- Administración --------------------------------------------------------->

          <li
            :class="{
              active: showConfiguration || isSubRouteActive('/configuracion/'),
            }"
            v-if="withPermissions('CONFIGURATION')"
          >
            <a
              @click="showConfiguration = !showConfiguration"
              style="cursor: pointer"
            >
              <i class="feather icon-settings"></i><span> Configuración </span>
              <i class="feather pull-right icon-chevron-right"></i>
            </a>
            <ul class="vertical-submenu in menu-open" v-if="showConfiguration">
              <li>
                <router-link
                  v-if="
                    withPermissions('CONFIGURATION-DASHBOARD-ABASTO') ||
                      withPermissions('CONFIGURATION-DASHBOARD-STATISTIC')
                  "
                  to="/configuracion/configuracionTableroControl"
                >
                  Configuración de tableros de control
                </router-link>
                <router-link
                  v-if="withPermissions('CONFIGURATION-ABASTO')"
                  to="/configuracion/tableroControlAbasto"
                >
                  Tablero de control abasto
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- End Navigationbar -->
    </div>
    <!-- End Sidebar -->
  </div>
</template>

<script>
import moment from "moment";
import { UtilFront, PermissionConstants } from "@/core";
import { SwalConfirm } from "@/core/SwalAlert";
import { mapState } from "vuex";

export default {
  data() {
    return {
      showRis: false,
      showReturn: false,
      showCatalogue: false,
      showAdmin: false,
      showReception: false,
      showIncidence: false,
      showWareHouse: false,
      showRemmision: false,
      showAudit: false,
      showPicking: false,
      showShipments: false,
      showDispensing: false,
      showOrders: false,
      showShipmentsRequest: false,
      showConfiguration: false,
      user: {},
      permissions: PermissionConstants,
      nameDay: moment().format("dddd"),
    };
  },
  computed: {
    ...mapState("auth", ["message"]),
  },
  methods: {
    toggleSitdris() {
      return (this.showRis = this.showRis == true ? false : true);
    },
    isRouteActive(nameRoute) {
      return this.$route.name != undefined
        ? this.$route.name.toLowerCase() == nameRoute.toLowerCase()
        : false;
    },
    isSubRouteActive(nameSubRoute) {
      return this.$route.path.startsWith(nameSubRoute);
    },
    showOrViewSubRoutes(subRoute) {
      let subRoutes = [];
      if (subRoute == "configuracion") {
        subRoutes = [
          "Roles",
          "Categorias",
          "FuentesFinanciamiento",
          "Jurisdicciones",
          "Productos",
          "Proveedores",
        ];
      } else if (subRoute == "recepcion") {
        subRoutes = ["RecepcionHistorico", "RecepcionPendiente"];
      } else if (subRoute == "devolucion") {
        subRoutes = ["DevolucionRealizada", "DevolucionEntrantes"];
      } else if (subRoute == "indicencia") {
        subRoutes = ["IncidenciaRealizada", "IncidenciaEntrante"];
      }
      for (let i = 0; i < subRoutes.length; i++) {
        let withPermission =
          this.user.permissions.filter((permission) => {
            return permission.name.toLowerCase() == subRoutes[i].toLowerCase();
          }).length > 0;
        let isAdmin =
          this.user.role.name.toLowerCase() == "Administrador".toLowerCase();
        withPermission = withPermission || isAdmin;
        if (withPermission) {
          return true;
        }
      }
      return false;
    },
    withPermissions(nameRouter) {
      let withPermission = true;
      try {
        withPermission =
          this.user.permissions.filter((permission) => permission == nameRouter)
            .length > 0;
      } catch (error) {
        console.log(error);
        withPermission = false;
      } finally {
        return withPermission;
      }
    },
    async showAlert() {
      if (this.message.spam) {
        const { isConfirmed } = await SwalConfirm.fire({
          title: "<strong>AVISO</strong>",
          icon:
            this.message.styleTypeAlert == "primary"
              ? "info"
              : this.message.styleTypeAlert,
          showCancelButton: false,
          html: "<b>" + this.message.message + "</b>",
        });
        if (!isConfirmed) {
          return;
        }
      }
    },
  },
  created() {
    this.user = UtilFront.getDataUser();
    console.log(this.nameDay);
  },
  watch: {
    $route(to, from) {
      setTimeout(() => this.showAlert(), 500);
    },
  },
};
</script>
