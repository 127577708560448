export default {
  password: "password",
  password_confirmation: "password confirmation",
  name: "name",
  user: "user",
  email: "e-mail address",
  email_confirmation: "e-mail address confirmation",
  lastname: "last name",
  phone: "phone",
  birth_date: "birth date",
  gender: "gender",
  manager: "manager",
  charge: "charge",
  department: "department",
  role: "role",
  branch: "branch",
  order: "order",
  bidding: "bidding",
  remission_ref: "reference remission",
  bill: "bill",
  supplier: "supplier",
  product: "product",
  lot: "lot",
  quantity: "quantity",
  cost_unity: "unit cost",
  expiry_date: "expiry date",
  source_of_financing: "source of financing",
  drivers_name: "driver's name",
  plate_number: "plate number",
  economic_number: "economic number",
  tag: "tap",
  amount_received: "amount received",
  comment: "comment",
  type_incidence: "type of incident",
  product_supplier: "product supplier",
  reason: "reason",
  professiona_license: "professional license",
  motive: "motive",
  clues: "clues",
  warehouse_ID: "warehouse ID",
  type_branch: "type of branch",
  jurisdiction: "jurisdiction",
  street: "street",
  outside_number: "outside number",
  inside_number: "inside number",
  colonia: "colonia",
  zip_code: "zip code",
  city: "city",
  road: "road",
  location: "location",
  description: "description",
  acronym: "acronym",
  categories: "categories",
  causes: "causes",
  unit_of_measure: "unit of measure",
  rfc: "RFC",
  folio: "prescription folio",
  number_file: "N° File",
  date_prescription: "date of prescription",
  date_dispensing: "date of dispensing",
  curp: "curp",
  source_of_financing: "source of financing",
  amount_prescribed: "amount prescribed",
  amount_denied: "amount denied",
  actual_quantity: "actual quantity",
  note: "note",
  location_warehouse: "location",
  type_request: "type of application",
  ref_request: "request reference (procurement)",
  products: "products",
  branch_main: "main branch",
  remissionType: "type remission",
  doctor: "doctor",
  doctor_:"doctor",
  procurement: "aprovisionamiento",
  level_dashboard: "nivel de tlabero de control",
  dasboard_distribution_board_main: "cuandro de distribución principal",
  file: "archivo",
  movement_motive: "motivo de movimiento",
  reference: "folio/referencia"

};
