var curp = {
  validate(value) {
    value = value.toUpperCase();
    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = value.match(re);
    if (!validado) return false;
    function digitoVerificador(curp17) {
      var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSuma = 0.0,
        lngDigito = 0.0;
      for (var i = 0; i < 17; i++)
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - (lngSuma % 10);
      if (lngDigito == 10) return 0;
      return lngDigito;
    }
    if (validado[2] != digitoVerificador(validado[1])) return false;
    return true;
  },
};

var file_pdf = {
  validate(value) {
    var isFile = /(.pdf|.PDF)$/i;
    if (isFile.exec(value[0].name)) {
      return true;
    }
    return false;
  },
};

export { curp, file_pdf };
