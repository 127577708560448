<template>
  <v-app v-show="branchOfficeActive" >
    <div v-if="isAuthenticated">
      <!-- <ModalSessionExpired :isExpired="isExpired" /> -->
      <Sidebar />
      <Topbar class="rightbar_two" />
      <router-view></router-view>
    </div>
    <div v-if="!isAuthenticated">
      <router-view></router-view>
    </div>
  </v-app>
</template>

<style></style>

<script>
/* eslint-disable */
import { Topbar, Sidebar, ModalSessionExpired } from "@/components";
import { mapState, mapActions } from "vuex";
import { LoginRequest } from "@/core/request";
import { UtilFront } from "@/core";

export default {
  data() {
    return {
      loadingPage: false,
    };
  },
  components: {
    Topbar,
    Sidebar,
    ModalSessionExpired,
  },
  created() {
    this.setValidationMessage();
  },
  methods: {
    ...mapActions({ setMessage: "auth/setMessage" }),
    setValidationMessage() {
      if (this.isAuthenticated) {
        LoginRequest.getMessage({})
          .then((response) => {
            let {
              message,
              dateExpired,
              usersExcluded,
              typeAlert,
              styleTypeAlert,
            } = response.data.data.data;
            let { success } = response.data.data;
            if (success) {
              let validation = false;
              validation = JSON.parse(usersExcluded).find((element) => {
                return element === UtilFront.getDataUser().idUser;
              });
              if (validation) {
                this.clearMessague();
              } else {
                this.setMessage({
                  message: message,
                  dateExpired: dateExpired,
                  type: styleTypeAlert,
                  alert: typeAlert == 3 || typeAlert == 1 ? true : false,
                  spam: typeAlert == 3 || typeAlert == 2 ? true : false,
                  styleTypeAlert: styleTypeAlert,
                });
              }
            } else {
              this.clearMessague();
            }
          })
          .catch(() => {
            this.clearMessague();
          })
          .finally(() => {});
      }
    },
    clearMessague() {
      let message = {
        type: 1,
        message: "",
        alert: false,
        styleTypeAlert: "",
        spam: false,
      };
      this.setMessage(message);
    },
  },
  watch: {
    isAuthenticated() {
      this.setValidationMessage();
    },
  },
  computed: {
    ...mapState("auth", ["isAuthenticated", "isExpired", "branchOfficeActive"]),
  },
};
</script>
