/**
 * @author Yair Alvarez
 * @company Erillam Healthcare
 * @version 1.0
*/
// Axios Instance
import { instance } from "@/core/ApiConfig.js";

// Set endpoints 
// Historial movimientos
const GET_ALL_NON_COMPLIANT_PRODUCTS = "hasvc/ha01";
// Productos
const GET_PRODUCTS_NON_COMPLIANT_PRODUCTS = "prsvc/ps11";  
// Lotes de productos 
const GET_LOTS_NON_COMPLIANT_PRODUCTS = "prmsvc/prm05";    // "/lots";
// Busqueda productos
const SEARCH_NON_COMPLIANT_PRODUCTS = "hasvc/ha08";    // "stockList";
// Crear movimiento - Bloquear productos
const BLOCK_STOCK_NEW_NON_COMPLIANT_PRODUCTS = "hasvc/ha02";
// Crear movimiento - Regresar stock
const RETURN_STOCK_NEW_NON_COMPLIANT_PRODUCTS = "hasvc/ha04";
// Crear movimiento - archivo PDF
const UPDATE_FILE_NEW_NON_COMPLIANT_PRODUCTS = "hasvc/ha16";
// Detalle movimiento
const GET_DETAILS_NON_COMPLIANT_PRODUCTS = "hasvc/ha06";
// Descarga PDF
const DOWNLOAD_PDF_NON_COMPLIANT_PRODUCTS = "hasvc/ha15";
// Producto en transito
const GET_ONTRANSIT_NON_COMPLIANT_PRODUCTS = "hasvc/ha09";
// Devolucion productos a almacen
const RETURN_STOCK_NON_COMPLIANT_PRODUCTS = "hasvc/ha10";

export default class NonCompliantProductRequest {
  static listAllNonCompliantProducts = 
    data => instance.post( GET_ALL_NON_COMPLIANT_PRODUCTS, data );
  
  static getProductListNonCompliantProducts = 
    () => instance.get( GET_PRODUCTS_NON_COMPLIANT_PRODUCTS );
  
  static getLotsNonCompliantProducts = 
    data => instance.post( GET_LOTS_NON_COMPLIANT_PRODUCTS, data );
   
  static searchProductsNonCompliantProducts =
    data => instance.post( SEARCH_NON_COMPLIANT_PRODUCTS, data );
  
  static blockStockNewNonCompliantProducts = 
    data => instance.put( BLOCK_STOCK_NEW_NON_COMPLIANT_PRODUCTS, data );
  
  static returnStockNewNonCompliantProducts = 
    data => instance.post( RETURN_STOCK_NEW_NON_COMPLIANT_PRODUCTS, data );
  
  static updateFileNewNonCompliantProducts = 
    data => instance.post( UPDATE_FILE_NEW_NON_COMPLIANT_PRODUCTS, data );
  
  static detailsNonCompliantProducts =
    data => instance.post( GET_DETAILS_NON_COMPLIANT_PRODUCTS, data );
  
  static downloadPDFNonCompliantProducts = 
    data => instance.post( DOWNLOAD_PDF_NON_COMPLIANT_PRODUCTS, data );
  
  static onTransitNonCompliantProducts =
    data => instance.post( GET_ONTRANSIT_NON_COMPLIANT_PRODUCTS, data );
  
  static returnStockNonCompliantProducts =
    data => instance.post( RETURN_STOCK_NON_COMPLIANT_PRODUCTS, data );
}
 