/**
 * @author Alan Saucedo
 * @company Erillam Healthcare
 * @version 1.0
 */

 import axios from "axios";
 import { ServicePath, UtilFront } from "@/core";
 
 export default class OrderStockRequest {
 
     static disableOrderStock(data) {
         return axios.put(ServicePath.ORDER_STOCK_DISABLE,data);
     }

     static orderStockDetail(data) {
        return axios.post(ServicePath.ORDER_STOCK_DETAIL,data);
    }

 }