/**
 * @author Ivo Danic Garrido
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";

export default class LotRequest {

  static findAllLotsAvailable(data) {
    return axios.post(ServicePath.GET_LOTS_BY_BRANCH_FISCAL_PRODUCT, data);
  }

  static findAllLotsByType(data) {
    return axios.post(ServicePath.PRODUCTO_REMMISION_LIST_ALL_LOT_BY_TYPE, data);
  }

  static findAllLotsAvailableFormList(data) {
    let lotArray = [];
    return new Promise((resolve, reject) => {
      this.findAllLotsAvailable(data).then(response => {
        for (var [index, value] of Object.entries(response.data.data.objects)) {
          //index;
          let lotItem = {
            text: value.lot + " | "+ value.expirationDate + " | " + value.quantity,
            value: value.stockId
          };
          lotArray.push(lotItem);
        }

        resolve(lotArray); 

      }).catch( (error) => {
        reject(error);
      });
    });
  }

  /*static findAllLotsByTypeKey(data) {
    let lotArray = [];
    return new Promise((resolve, reject) => {
      this.findAllLotsByType(data).then(response => {
        for (var [index, value] of Object.entries(response.data.data.data.objects)) {
          //index;
          let lotItem = {
            text: value.lot + " | "+ value.expirationDate + " | " + value.quantity,
            value: value.stockId
          };
          lotArray.push(lotItem);
        }

        resolve(lotArray); 

      }).catch( (error) => {
        reject(error);
      });
    });
  }*/


}
