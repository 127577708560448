<template>
  <div>
    <b-dropdown
      id="dropdown-2"
      size="lg"
      dropleft
      variant="none"
      toggle-class="text-decoration-none p-0"
      no-caret
    >
      <template #button-content>
        <span class="hand mt btn-b btn btn-rounded btn-perfil">
          <i class="fa fa-sort-desc fa-pull-right"></i>
          {{ $t("topbar.incoming") }} / {{ $t("topbar.outgoint") }}
        </span>
      </template>
      <b-dropdown-item @click="showModal(1, true)">
        <i class="feather icon-log-in mr-2" aria-hidden="true"></i
        >{{ $t("topbar.incoming") }}</b-dropdown-item>
      <b-dropdown-item @click="showModal(2, true)"
        ><i class="feather icon-log-out mr-2" aria-hidden="true"></i
        >{{ $t("topbar.outgoint") }}</b-dropdown-item
      >
    </b-dropdown>

    <!--   <button @click="showModal(1, true)" class="btn-shedule">
      <i class="feather icon-log-in mr-2" aria-hidden="true"></i
      >{{ $t("topbar.incoming") }}
    </button>
    <button @click="showModal(2, true)" class="btn-shedule">
      <i class="feather icon-log-out mr-2" aria-hidden="true"></i>{{
        $t("topbar.outgoint")
      }}
    </button> -->

    <v-dialog v-model="dialog" max-width="420" min-width="320" persistent>
      <v-card v-if="dataTime != null">
        <v-card-title class="text-center">
          <div class="col-md-12 pb-0">
            <h3 v-if="type == 1">{{ $t("topbar.entry_records") }}</h3>
            <h3 v-else>{{ $t("topbar.check_out") }}</h3>
          </div>
        </v-card-title>
        <v-card-text class="text-center">
          <div class="col-md-12">
            <p class="regular bold">
              <span v-if="type == 1">
                {{ $t("topbar.messague_success_entry") }}</span
              >
              <span v-else> {{ $t("topbar.messague_success_out") }}</span>
            </p>
            <h1>
              <span>{{ dataTime }}</span>
            </h1>
          </div>
          <div class="text-center">
            <button
              type="button"
              class="btn btn-outline-success mt-4"
              @click="hideModal()"
            >
              <i class="feather icon-check mr-1"></i> {{ $t("global.accept") }}
            </button>
          </div>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <div class="col-md-12 pb-0">
            <h3 v-if="type == 1">{{ $t("topbar.entry_records") }}</h3>
            <h3 v-else>{{ $t("topbar.check_out") }}</h3>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="col-md-12 pb-0">
            <p class="text--primary">{{ $t("topbar.enter_password") }}</p>
          </div>
          <div class="col-md-12 pt-0">
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit">
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="password"
                    rules="required"
                  >
                    <v-text-field
                      :error-messages="errors"
                      autocomplete="current-password"
                      v-model="currentPassword"
                      type="password"
                      :label="$t('topbar.password')"
                      placeholder="***************"
                      outlined
                      dense
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="text-center">
                  <button
                    class="btn btn-outline-success mr-4 mt-4"
                    type="submit"
                    :disabled="invalid"
                  >
                    <i class="feather icon-save mr-1"></i>
                    {{ $t("global.register") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger mt-4"
                    @click="hideModal()"
                  >
                    <i class="feather icon-x mr-1"></i>
                    {{ $t("global.cancel") }}
                  </button>
                </div>
              </form>
            </validation-observer>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { LoginRequest } from "@/core/request";
import { UtilFront, PermissionConstants } from "@/core";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import moment from "moment";

export default {
  data() {
    return {
      dialog: false,
      type: null,
      currentPassword: "",
      sending: false,
      loading: null,
      dataTime: null,
      //Permisos
      permissions: PermissionConstants,
    };
  },
  methods: {
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    submit() {
      if (!this.sending) {
        let infoUser = UtilFront.getDataUser();
        //Muestro la pantalla de carga
        this.loading = UtilFront.getLoaddingDots();
        this.sending = true; //Agregar despues del if, con el fin de cambiar el estado despues de entrar a la validacion
        LoginRequest.postScheduleRegistration({
          typeTime: this.type,
          usuarioId: infoUser.idUser,
          userEmail: infoUser.email,
          userPassword: this.currentPassword,
        })
          .then((response) => {
            if (response.data.data.success) {
              this.dataTime = moment(
                response.data.data.data.recordedTime
              ).format("h:mm a");
              this.type = response.data.data.data.typeTime;
              this.sending = false;
              if (response.data.data.data.typeTime === 1)
                this.$toast.success(
                  "Su horario de entrada se registro con exito."
                );
              else
                this.$toast.success(
                  "Su horario de salida se registro con exito."
                );
            } else {
              this.$toast.error(this.$t(response.data.data.message));
              this.sending = false;
            }
          })
          .catch(() => {
            this.$toast.error("Ocurrio un error interno");
          })
          .finally(() => {
            this.sending = false;
            this.loading.hide();
          });
      }
    },
    reset() {
      this.sending = false;
      this.currentPassword = "";
      this.dialog = false;
      this.type = null;
      this.dataTime = null;
    },
    showModal(type, dialog) {
      this.reset();
      this.type = type;
      this.dialog = dialog;
    },
    hideModal() {
      this.dialog = false;
      this.reset();
    },
  },
  created() {
    this.reset();
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>

<style lang="scss">
.schedule > button {
  border-radius: 10px !important;
  background-color: #fff;
  font-size: 14px;
  color: #048e61;
}

.schedule > button:hover {
  background-color: #048e61;
  color: #fff;
}
</style>
