import Vue from "vue";
import typeEntityMovement from "@/assets/json/typeEntityMovement.json";

export default class UtilFront {
  static secretWord = "ERILLAMFMEDICAL2021EIFAJ\\*-*//";
  static keyDataUser = "14SDHVE*DSMJS*XDXADALV?$DEP--@PT4";

  static setIdUser(model) {
    model["idUser"] = this.getDataUser().idUser;
    return model;
  }

  static setUserID(model) {
    model["userID"] = this.getIdUser();
    return model;
  }

  static getIdUser() {
    return this.getDataUser().idUser;
  }

  static getByIdBranchName(id) {
    return this.getDataUser().branchOfficesSimple.filter(
      (branchOfficesSimple) => branchOfficesSimple.value.idBranchoffice == id
    );
  }

  /**
   * It returns the value of the keyDataUser key in the sessionStorage object.
   * @returns The dataUser object.
   */
  static getDataUser() {
    return JSON.parse(this.getOfSession(this.keyDataUser));
  }

  static getPermissions() {
    return this.getDataUser().permissions;
  }

  static setDataUser(value) {
    this.setOfSession(this.keyDataUser, JSON.stringify(value));
  }

  static getOfSession(key) {
    return this.decrypt(new Vue().$session.get(key));
  }

  static setOfSession(key, value) {
    new Vue().$session.set(key, this.encrypt(value));
  }

  /**
   * It returns a new Vue instance, which is then used to call the .show() method.
   * @returns A promise.
   */
  static getLoadding() {
    return new Vue().$loading.show({
      canCancel: false,
      color: "#42b983",
      loader: "dots",
      width: 95,
      height: 165,
      is_full_page: true,
      backgroundColor: "#ffffff",
      opacity: 0.5,
      zIndex: 999,
    });
  }

  /**
   * It returns a new Vue instance, which is then used to call the .show() method.
   * @returns A promise.
   */
  static getLoaddingDots() {
    return new Vue().$loading.show({
      canCancel: false,
      color: "#42b983",
      loader: "dots",
      width: 120,
      height: 120,
      backgroundColor: "#ffffff",
      opacity: 0.5,
      zIndex: 999,
    });
  }

  static encrypt(valueNoEncryped) {
    const encryptedText = new Vue().CryptoJS.AES.encrypt(
      valueNoEncryped,
      this.secretWord
    ).toString();
    return encryptedText;
  }

  /**
   * Decrypts the encrypted value using the secret word.
   * @param valueEncrypted - The value to be decrypted.
   * @returns The decrypted text.
   */
  static decrypt(valueEncrypted) {
    const decryptedText = new Vue().CryptoJS.AES.decrypt(
      valueEncrypted,
      this.secretWord
    ).toString(new Vue().CryptoJS.enc.Utf8);
    return decryptedText;
  }

  static withPermissionName(permissionName) {
    return (
      this.getPermissions().filter((permission) => permission == permissionName)
        .length == 1
    );
  }

  static getNameStatus(id) {
    let name = "";
    switch (id) {
      case 1:
        name = "enviar a pendientes de validar.";
        break;
      case 3:
        name = "para realizar la validación.";
        break;
      case 4:
        name = "para realizar la autorización.";
        break;
      case 6:
        name = "para realizar la revalidación.";
        break;
      case 7:
        name = "para realizar la reautorización.";
        break;
      default:
        name = "";
    }
    return name;
  }

  static getNameProccess(id) {
    let name = "";
    switch (id) {
      case 3:
        name = "en proceso de validación.";
        break;
      case 4:
        name = "en proceso de autorización.";
        break;
      case 6:
        name = "en proceso de revalidación.";
        break;
      case 7:
        name = "en proceso de reautorización.";
        break;
      default:
        name = "";
    }
    return name;
  }

  //Special characters validation (no spacebar,numbers,capital letters only)
  static RegExNoSpace() {
    return '|regex:^[0-9A-ZÁÉÍÑÓÚÜ]*$';
  };
  //Special characters validation (one spacebar,numbers,capital letters only)
  static RegExSpace() {
    return '|regex:^([0-9A-ZÁÉÍÑÓÚÜ.]+ )*[0-9A-ZÁÉÍÑÓÚÜ.]+$';
  };

  static getUpdateoObject(modelForm, modelFormCopy) {
    const payload = {};
    for (const [key, value] of Object.entries(modelForm)) {
      if (value != modelFormCopy[key])
        payload[key] = value;
    }
    if (Object.keys(payload).length === 0) {
      return false;
    }
    return payload;
  }

  static applyFilters(list, filters) {
    return list.filter(
      (item) =>
        (filters.typeBranchValues.length === 0 ||
          filters.typeBranchValues.includes(item.typeBranch.toString())) &&
        (filters.jurisdictionsValue.length === 0 ||
          filters.jurisdictionsValue.includes(
            item.jurisdiction.toString()
          )) &&
        (filters.provincesValue.length === 0 ||
          filters.provincesValue.includes(item.province.toString())) &&
        (filters.zonesValue.length === 0 ||
          filters.zonesValue.includes(item.zone.toString())) &&
        (filters.statusValue === null || filters.statusValue === item.status) &&
        (item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(filters.search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()) + item.branchOfficeKey.toLowerCase().includes(filters.search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()))
    );
  }

  static windowScrollTo() {
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }

  static isUndefined(payload) {
    return payload == undefined;
  }

  static isNull(payload) {
    return payload == null;
  }

  static isEmpty(payload) {
    return payload == "";
  }

  static typeRemissionName(payload) {
    const typeName = {
      1: "Proveedor",
      2: "Recepción",
      3: "Devolución",
      4: "Donación",
      5: "Compra",
      6: "Controlado",
      7: "Carta Canje",
      8: "Incorporación a Inventario",
    };
    return typeName[payload] || "No definido"
  }

  static typeMovement(payload) {
    const typeName = {
      1: "Registro",
      2: "Actualización",
      3: "Deshabilitado",
      4: "Modificación de estado",
    };
    return typeName[payload] || "No definido"
  }
}
