/**
 * @author Fernando FH
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath,UtilFront } from "@/core";

export default class CategoryRequest {
    static listAll() {
        return axios.post(ServicePath.CATEGORY_LIST_ALL);
    }
    static add(object) {
        return axios.post(ServicePath.CATEGORY_ADD, UtilFront.setUserID(object));
    }
    static update(object) {
        return axios.post(ServicePath.CATEGORY_UPDATE, UtilFront.setUserID(object));
    }
}