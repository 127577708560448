export const IS_LOADING = (state, val) => {
  state.isLoading = val;
};

export const TAP_SHOW = (state, val) => {
  state.tabs = val;
};

export const IS_DISTRIBUTION = (state, val) => {
  state.idDistributionBoard = val;
};

export const USER_NIVEL_SUCCESS = (state, val) => {
  state.modelUser = val;
  state.idDistributionBoard = val.distributionBoardId
    ? val.distributionBoardId
    : "DNB-1";
};

export const USER_NIVEL_ERROR = (state) => {
  state.tabs = null;
  state.branchSelection = 0;
  state.modelUser = {
    idDashboard: null,
    levelDashboard: null,
    type: null,
    users: null,
    jurisdictionId: null,
    userListBranchOffice: null,
    distributionBoardId: null,
    jurisdictionName: null
  };
};

export const LEVEL_BRANCH_SELECT = (state, val) => {
  state.branchSelection = val;
};

export const SET_URL_DASHBOARD = (state, val) => {
  state.urlStaticDashboard = val;
};

export const LIST_DASHBOARD_USER_SUCCESS = (state, val) => {
  state.dashboardList = val;
  state.dashboardType = val[0]?.value ? val[0].value : 0;
};

export const TYPE_DASHBOARD_USER = (state, val) => {
  state.dashboardType = val;
};

export const LIST_DASHBOARD_USER_ERROR = (state) => {
  state.dashboardType = "0";
  state.dashboardList = [];
};
