export default () => ({
  isLoading: false,
  tabs: null,
  modelUser: {
    idDashboard: null,
    levelDashboard: null,
    type: null,
    users: null,
    jurisdictionId: null,
    userListBranchOffice: null,
    distributionBoardId: null,
    jurisdictionName: null,
  },
  branchSelection: 0,
  typeDashboard: [
 /*    {
      value: "DNB-1",
      text: "CLAVES BÁSICAS (95)",
    },
    {
      value: "DNB-12",
      text: "CLAVES BASICAS (67)",
    }, */
    {
      value: "DNB-44",
      text: "CLAVES BÁSICAS (133)",
    },
    {
      value: "DNB-43",
      text: "CLAVES BÁSICAS (109)",
    },
    {
      value: "DNB-45",
      text: "SALUD MENTAL",
    },
    {
      value: "DNB-46",
      text: "CLAVES DE PLANIFICACIÓN FAMILIAR",
    },
  ],
  idDistributionBoard: "DNB-44",
  dashboardList: [],
  dashboardType: "0",
  urlStaticDashboard: ""
});
