/**
 * @author Alan Saucedo
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { headers } from "@/other/utils/Headers";
import { SECURE_ENDPOINT, SECURE_ENDPOINT_REPORTS } from "@/core/AppConfiguration";

const DASHBOARD_DETAIL = SECURE_ENDPOINT + "dashboard/statistics";
const DASHBOARD_DETAIL_SUPPLY = SECURE_ENDPOINT + "dashboard/general";
const DASHBOARD_DETAIL_JURISDICTION = SECURE_ENDPOINT + "dashboard/jurisdiction";
const DISTRIBUTION_BOARD_DETAIL = SECURE_ENDPOINT + "dashboard/branch-office";
const MEDICAL_PRESCRIPTION_BY_PRODUCT = SECURE_ENDPOINT + "dashboard/medical-prescription";
const DASHBOARD_NIVEL_USER = SECURE_ENDPOINT + "dashboard/level-user";

const REPORT_PRESCRIPTION = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt06";
const EXCEL_DASHBOARD_BRANCH_OFFICE = SECURE_ENDPOINT_REPORTS + "exsvc/ex27";

const EXCEL_DASHBOARD_TABLE_STATISTICS = SECURE_ENDPOINT + "dashboard/dispensing";
const ALL_DASHBOARD_USER = SECURE_ENDPOINT + "dashboard/user";
const GET_URL_DASHBOARD_STATISTICS = SECURE_ENDPOINT + "dashboard/dashboard-type";
  

export default class DashboardRequest {

  static blob = { responseType: "blob" };

  static dashboardNivelUser = async (data) => {
    return await axios
      .post(DASHBOARD_NIVEL_USER, data, headers())
      .then((response) => response)
      .catch((error) => error);
  };

  static dashboardBranchOficceDetail = async (data) => {
    return await axios
      .post(DISTRIBUTION_BOARD_DETAIL, data, headers())
      .then((response) => response)
      .catch((error) => error);
  };

  static dashboardDetail = async (data) => {
    return await axios
      .post(DASHBOARD_DETAIL, data, headers())
      .then((response) => response)
      .catch((error) => error);
  };

  static dashboardDetailChart = async (data) => {
    return await axios
      .post(DASHBOARD_DETAIL_SUPPLY, data, headers())
      .then((response) => response)
      .catch((error) => error);
  };

  static dashboardDetailJurisdiction = async (data) => {
    return await axios
      .post(DASHBOARD_DETAIL_JURISDICTION, data, headers())
      .then((response) => response)
      .catch((error) => error);
  };

  static getUrlDashboardStatistics = async (data) => {
    return await axios
      .post(GET_URL_DASHBOARD_STATISTICS, data, headers())
      .then((response) => response)
      .catch((error) => error);
  };

  static dashboardBranchOficceDispensing = async (data) => {
    return await axios
      .post(MEDICAL_PRESCRIPTION_BY_PRODUCT, data, headers())
      .then((response) => response)
      .catch((error) => error);
  };

  static dashboardBranchOficceDispensingTable = async () => {
    return await axios
      .post(EXCEL_DASHBOARD_TABLE_STATISTICS, headers())
      .then((response) => response)
      .catch((error) => error);
  };

  static listDashboardUser = async (data) => {
    return await axios
      .post(ALL_DASHBOARD_USER, data, headers())
      .then((response) => response)
      .catch((error) => error);
  };

  static reportDashboardBranchOffice = async (data, name) => {
    return await axios
      .post(EXCEL_DASHBOARD_BRANCH_OFFICE, data, this.blob)
      .then((response) => {
        this.download(response, name ? name.name : "porcentajeSucursal.xlsx");
      });
  };

  static reportPrescription = async (data) => {
    return await axios
      .post(REPORT_PRESCRIPTION, data, this.blob)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );
        window.open(url);
      });
  };

  static download(response, fileName) {
    const url = URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.ms-excel",
      })
    );
    /* Simulo el click */
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }
}
