<template>
  <div>
    <a v-if="env_province == 'mxver'" class="logo"
      ><img
        v-if="!topbar"
        src="@/assets/images/logo-veracruz.png"
        class="img-fluid"
        alt="logo"
        style="width: 100%"
    /></a>
    <p class="regular mb-0" v-if="env_province == 'mxver' && topbar">
      Veracruz
    </p>
    <a v-if="env_province == 'mxjal'" class="logo"
      ><img
        v-if="!topbar"
        src="@/assets/images/logo-jalisco.png"
        class="img-fluid"
        alt="logo"
        style="height: 100%; width: 100%"
    /></a>
    <p class="regular mb-0" v-if="env_province == 'mxjal' && topbar">
      Jalisco
    </p>
    <a v-if="env_province == 'mxchp'" class="logo"
      ><img
        v-if="!topbar"
        src="@/assets/images/logo-chiapas.png"
        class="img-fluid"
        alt="logo"
        style="height: 100%; width: 90%"
      />
    </a>
    <p class="regular mb-0" v-if="env_province == 'mxchp' && topbar">
      Chiapas
    </p>
    <h3 v-if="env_province == 'qa' && !topbar">
      <i class="feather icon-terminal"></i> Testing QA
    </h3>
    <p class="regular mb-0" v-if="env_province == 'qa' && topbar">
      Testing QA
    </p>
    <h3 v-if="env_province == 'dev' && !topbar">
      <i class="feather icon-code"></i> Developer
    </h3>
    <p class="regular mb-0" v-if="env_province == 'dev' && topbar">
      Dev
    </p>
  </div>
</template>

<script>
export default {
  props: {
    topbar: {
      default: false,
    },
  },
  data() {
    return {
      env_province: process.env.VUE_APP_PROVINCE_LOCALE,
    };
  },
};
</script>
