/**
 * @author Jesus TC
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";

export default class PreRequest {
  static responseTypeV = { responseType: "blob" };
  static listAllPreRequest(data) {
    return axios.post(ServicePath.PREREQUEST_LIST_ALL, data);
  }
  static addPreRequest(data) {
    return axios.post(ServicePath.CREATE_PREREQUEST, data);
  }
  static detailPreRequest(data) {
    return axios.post(ServicePath.DETAIL_PREREQUEST, data);
  }
  static updatePreRequest(data) {
    return axios.post(ServicePath.UPDATE_PREREQUEST, data);
  }

  static updateStatusRequest(data) {
    return axios.post(ServicePath.UPDATE_STATUS_PREREQUEST, data);
  }

  static getProductBoardDistribution(data) {
    return axios.post(ServicePath.PRODUCT_LIST_BOARD_DISTRIBUTION, data);
  }

  static reportPreRequest(data) {
    return axios
      .post(ServicePath.DETAIL_PREREQUEST_PDF, data, this.responseTypeV)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );
        window.open(url);
      });
  }

  /* [NEW] Services - REQUEST */
  static detailRequest(data) {
    return axios.post(ServicePath.GET_DETAIL_REQUEST, data);
  }
  static addRequestSave(data) {
    return axios.post(ServicePath.SAVE_REQUEST, data);
  }
  static getAllRequest(data) {
    return axios.post(ServicePath.GET_REQUEST_ALL, data);
  }
  static getAllRequestGeneral(data) {
    return axios.post(ServicePath.GET_REQUEST_ALL_VALIDATED, data);
  }
  static setUpdateRequestValidation(data) {
    return axios.post(ServicePath.SET_UPDATE_REQUEST_VALIDATION, data);
  }
  static assingUserRequest(data) {
    return axios.post(ServicePath.ASIGNATION_REQUEST_USER, data);
  }
  static getStatusRequestAssigned(data) {
    return axios.post(ServicePath.GET_STATUS_REQUEST_ASSIGNED, data);
  }
  static setStatusRequest(data) {
    return axios.post(ServicePath.SET_STATUS_REQUEST, data);
  }
  
    static getAllRequestSelect(data) {
    return axios.post(ServicePath.LIST_ALL_SHIPMENT_REQUEST, data);
  }

}
