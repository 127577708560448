import Vue from "vue";
import VueI18n from "vue-i18n";

import attributesEs from "@/locales/attributes/es";
import messagesEs from "@/locales/messages/es.json";
import attributesEn from "@/locales/attributes/en";
import messagesEn from "@/locales/messages/en.json";

Vue.use(VueI18n);

function loadLocaleMessages() {
  // Agregación de los JSON locales
  const locales = require.context(
    "@/locales/i18n",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  let messages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  // Agregación de idioma español para los mensajes de error.
  messages.es.fields = attributesEs;
  messages.es.validation = messagesEs.messages;
  // Agregación de idioma ingles para los mensajes de error.
  messages.en.fields = attributesEn;
  messages.en.validation = messagesEn.messages;

  return messages;
}

function getLocale() {
  const LSLocale = localStorage.getItem("locale");
  if (LSLocale) return LSLocale;
  let locale = process.env.VUE_APP_I18N_LOCALE || "en";
  const userLocale = navigator?.languages?.[0] || navigator?.language;
  locale = userLocale ? userLocale.split("-")?.[0] : locale;
  return locale;
}

export default new VueI18n({
  locale: getLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
});
