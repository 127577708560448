import state from "./state";
import * as mutations from "./mutations";
import * as actions from "./actions";

const remission = {
  namespaced:true,
  actions,
  state,
  mutations,
};

export default remission;
