import { instance } from "@/core/ApiConfig.js";

const REMISSIONS_AUDIT_LIST = "audit/remissions";
const SHIPMENT_AUDIT_LIST = "audit/shipments";
const SHIPMENT_AUDIT_LIST_MOVEMENTS = "audit/movements";

export default class AuditRequest {
  static getAllRemissionAudit = (pages) => {
    return instance.post(REMISSIONS_AUDIT_LIST, pages);
  };

  static getAllShipmentAudit = (pages) => {
    return instance.post(SHIPMENT_AUDIT_LIST, pages);
  };

  static getAllAuditMovement = (pages) => {
    return instance.post(SHIPMENT_AUDIT_LIST_MOVEMENTS, pages);
  };
}
