/**
 * @author Jesus TC
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath, UtilFront } from "@/core";

export default class EmployeeRequest {

  static listAllEmployees(data) {
    return axios.post(ServicePath.EMPLOYEE_LIST_ALL, data);
  }

  static addEmployee(data) {
    return axios.post(ServicePath.CREATE_EMPLOYEE, data);
  }

  static updateEmployee(data) {
    return axios.post(ServicePath.UPDATE_EMPLOYEE, data);
  }

  static listAllDepartment(data) {
    return axios.post(ServicePath.DEPARTMENT_LIST_ALL, data);
  }
  
  static addDepartment(data) {
    return axios.post(ServicePath.CREATE_DEPARTMENT, data);
  }

  static updateDeparmement(data) {
    return axios.post(ServicePath.UPDATE_DEPARTMENT, data);
  }

  static listAllPosition(data) {
    return axios.post(ServicePath.POSITION_LIST_ALL, data);
  }
  
  static addPosition(data) {
    return axios.post(ServicePath.CREATE_POSITION, data);
  }

  static updatePosition(data) {
    return axios.post(ServicePath.UPDATE_POSITION, data);
  }

}
