/**
 * @author Ivo Danic Garrido
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";

export default class WarehouseRequest {
  responseData = [];
  static findAllStockWharehouse(id) {
    return axios.post(ServicePath.GET_ALL_STOCK_FROM_USER_BRANCH, {
      idUser: id,
    });
  }

  static findAllStockWithObservations(id) {
    return axios.post(ServicePath.GET_ALL_STOCK_WITH_OBSERVATION_BY_BRANCH, {
      idUser: id,
    });
  }

  static stockHistoryOrigin(data) {
    return axios.post(ServicePath.STOCK_HISTORY_ORIGIN, data);
  }

  static stockHistoryDestination(data) {
    return axios.post(ServicePath.STOCK_HISTORY_DESTINATION, data);
  }

  static stockDetail(data) {
    return axios.post(ServicePath.STOCK_DETAIL, data);
  }

  static getAllStockFromUserBranch(data) {
    return axios.post(ServicePath.GET_ALL_STOCK_FROM_USER_BRANCH, data);
  }

  static moveStockLocation(data) {
    return axios.post(ServicePath.MOVE_STOCK_LOCATION, data);
  }

  static getAllStockFromUserBranchExpired(data) {
    return axios.post(ServicePath.GET_ALL_STOCK_FROM_USER_BRANCH_EXPIRED, data);
  }
}
