/**
 * @author Ivo Danic Garrido
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";

export default class ProductResquest {
  static findAllProducts() {
    return axios.post(ServicePath.GET_ALL_PRODUCTS);
  }

  static findAllProductsByFiscalFoundAvailable(data) {
    return axios.post(ServicePath.GET_ALL_PRODUCTS_AVAILABLE, data);
  }

  static findAllProductsInStockByBranchOffice(data) {
    return axios.post(ServicePath.GET_ALL_PRODUCT_IN_STOCK, data);
  }

  static findAllProductsInStockByBranchOfficeExpired(data) {
    return axios.post(ServicePath.GET_ALL_PRODUCT_IN_STOCK_EXPIRED, data);
  }

  static findAllProductosByStockByType(data) {
    return axios.post(ServicePath.PRODUCT_STOCK_LIST_ALL_BY_TYPE, data);
  }

  static findAllProductsByCauses() {
    let productItemArray = [];
    this.findAllProducts().then(response => {
      for (var [index, value] of Object.entries(response.data.data.objects)) {
        index;
        let productItem = {
          text: value.productKey,
          value: value.idProduct
        };
        productItemArray.push(productItem);
      }
    });
    return productItemArray;
  }

  static findAllProductsByCausesAvailable(data) {
    let productItemArray = [];

    return new Promise((resolve, reject) => {

      this.findAllProductsByFiscalFoundAvailable(data).then(response => {
        for (var [index, value] of Object.entries(response.data.data.objects)) {
          index;
          let productItem = {
            text: value.causes +' | '+value.name + " " + value.description,
            value: value.idProduct
          };
          productItemArray.push(productItem);
        }
        
        resolve(productItemArray); 

      }).catch( (error) => {

        reject(error);

      });

    })

    //return productItemArray;
  }

  static findAllProductsByOrders(data) {
    return axios.post(ServicePath.GET_ALL_PRODUCTS_BY_ORDER, data);
  }

  static findAllProductsByOrdersKey(data) {
    let productItemArray = [];

    return new Promise((resolve, reject) => {

      this.findAllProductsByOrders(data).then(response => {

        for (var [index, value] of Object.entries(response.data.data.objects)) {
          let productItem = {
            idStock: value.idStock,
            fiscalFound: value.fiscalFound,
            lot: value.lot,
            product: value.product,
            productKey: value.productKey,
            quantity: value.quantity,
            prescription: value.prescription,
            expirationDate: value.expirationDate,
            fiscalFound: value.fiscalFound
          };
          productItemArray.push(productItem);
        }

        resolve(productItemArray); 

      }).catch( (error) => {

        reject(error);

      });

    })

  }


  static listAllStockByBranchOfficeAndAutMedications(data) {
    return axios.post(ServicePath.STOCK_BY_BRANCH_OFFICE, data);
  }





}
