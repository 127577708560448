export const DASHBOARD_BRANCH_OFFFICE_SUCCESS = (state, val) => {
  state.distributionBoard.authorizedProduct = val.authorizedProduct;
  state.distributionBoard.unauthorizedProduct = val.unauthorizedProduct;
  state.generalAverage.percentageSupply = val.percentageSupply;
  state.generalAverage.percentageKey = val.percentageKey;
  state.productCount = val.productCount;
  state.warehauseDetail = val.warehauseDetail;
  state.warehauseDetailStockDistributionBoard = val.warehauseDetailStockDistributionBoard;
  state.nameBranchOffice = val.nameBranchOffice;
  state.modelBranchOffice.nameBranchOffice = val.nameBranchOffice;
  state.modelBranchOffice.clues = val.clues;
  state.modelBranchOffice.zone = val.zone;
  state.idBranchOffice = val.idBranchOffice;
};

export const DASHBOARD_BRANCH_OFFFICE_ERROR = (state) => {
  state.idBranchOffice = 0;
  state.distributionBoard.authorizedProduct = 0;
  state.distributionBoard.unauthorizedProduct = 0;
  state.generalAverage.percentageSupply = 0;
  state.generalAverage.percentageKey = 0;
  state.productCount = 0;
  state.warehauseDetail = [];
  state.warehauseDetailStockDistributionBoard = [];
  state.modelBranchOffice.nameBranchOffice = "";
  state.modelBranchOffice.clues = "";
  state.modelBranchOffice.zone = "";
};

export const DASHBOARD_BRANCH_OFFFICE_DISPENSING_SUCCESS = (state, val) => {
  state.cluesKey = val.cluesKey;
  state.nameProduct = val.nameProduct;
  state.dispensingData = val.dispensingData;
};

export const DASHBOARD_BRANCH_OFFFICE_DISPENSING_ERROR = (state) => {
  state.cluesKey = "";
  state.nameProduct = "";
  state.dispensingData = [];
};

export const DASHBOARD_BRANCH_OFFFICE_DETAIL_SUCCESS = (state, val) => {
  state.modelDetails = val;
};

export const DASHBOARD_BRANCH_OFFFICE_DETAIL_ERROR = (state) => {
  state.modelDetails = {
    shipments: 0,
    medicalPresciptions: 0,
    dispensingProducts: 0,
  };
};
