<template>
  <v-dialog v-model="isExpired" persistent max-width="320">
    <v-card>
      <v-card-title class="text-center"> La sesión ha caducado </v-card-title>
      <v-card-text class="text-center">Vuelve a iniciar sesión</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="sessionLogout()">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    isExpired: {
      default: false,
    },
  },
  methods: {
    ...mapMutations("global", {
      clearUser: "USER_NIVEL_ERROR",
    }),
    ...mapMutations("auth", {
      updateBrand: "UPDATE_VALUE",
      sessionUpdate: "SESSION_UPDATE",
      updateBranchOffice: "UPDATE_BRACH_OFFICCE",
      sessionExpired: "UPDATE_EXPIRED_SESSION",
    }),
    sessionLogout() {
      this.$session.destroy();
      this.$router.push("/");
      this.sessionUpdate(false);
      this.updateBranchOffice(null);
      this.clearUser();
      this.sessionExpired({
        isExpired: false,
        expiredTime: "",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  display: block;
}
</style>
