/**
 * @author Alan Saucedo
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";

export default class DoctorRequest {

    static addDoctor(data) {
        return axios.post(ServicePath.DOCTOR_ADD, data );
    }

    static updateDoctor(data) {
        return axios.post(ServicePath.DOCTOR_UPDATE,data);
    }

    static findAllDoctorByBranchOffice(data) {
        return axios.post(ServicePath.DOCTOR_LIST_ALL_BY_BRANCH_OFFICE,data);
    }


    static findAllDoctorByBranchOfficeKeyAvailable(data) {
        let doctorArray = [];
        this.findAllDoctorByBranchOffice(data).then(response => {
          for (var [index, value] of Object.entries(response.data.data.objects)) {
        
            let doctoritem = {
              text: value.name + " " + value.lastName + " | " + value.professionalId,
              value: value.idDoctor
            };
            doctorArray.push(doctoritem);
          }
        });
        return doctorArray;
      }

}