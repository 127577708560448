<template>
  <div v-if="message.alert" class="message">
    <div class="message-alert">
      <v-alert
        dense
        border="left"
        text
        :type="message.styleTypeAlert"
        :icon="showAlertType(message.type)"
      >
        {{ message.message }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["message"]),
  },
  methods: {
    showAlertType(value) {
      switch (value) {
        case "success":
          return "feather icon-check-circle";
        case "info":
          return "feather icon-info";
        case "warning":
          return "feather icon-alert-triangle";
        case "error":
          return "feather icon-alert-triangle";
        case "primary":
          return "feather icon-info";
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .message {
    margin: 20px 5px 0px 5px;
  }
}

@media (min-width: 767px) {
  .message {
    margin: 0px 30px 0 30px;
  }
}
.message {
  background-color: #fff;
  border-radius: 4px;
}
</style>
