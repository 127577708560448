/**
 * @author Ivo Danic Garrido
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";
import { instance } from "@/core/ApiConfig.js";



const USER_GET_ALL_LIST = "users-branch-offices/orders";

export default class OrderRequest {


  static orderDetail(data) {
    return axios.post(ServicePath.ORDER_DETAIL, data);
  } 


  static findAllOrderByUser(data) {
    return axios.post(ServicePath.GET_ALL_ORDER_BY_USER, data);
  }


  static orderRollBack(data) {
    return axios.post(ServicePath.ORDER_ROLL_BACK, data);
  }

  static orderChangeShipping(data) {
    return axios.post( ServicePath.ORDER_CHAGE_SHIPPING, data );
  }

  static getAllUserList = (data) => {
    return instance.post(USER_GET_ALL_LIST, data);
  };

}
