/**
 * @author Antonio Gaspar
 * @company Erillam Healthcare
 * @version 1.0
 */

 import axios from "axios";
 import { ServicePath } from "@/core";

 export default class ProvinceRequest {

    static listAll() {
        return axios.post(ServicePath.PROVINCE_LIST_ALL);
    }
    
 }