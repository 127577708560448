export const headers = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json;  charset=UTF-8",
  };
};

export const headersToken = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json;  charset=UTF-8",
    Authorization: `Bearer ${localStorage.getItem("lN5cV8UwIgkpAujO")}`,
  };
};

export default { headers, headersToken };
