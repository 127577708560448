import { DashboardRequest } from "@/other/core";

export const dashboardBranchOficceDetailAction = async (
  { commit },
  payload
) => {
  if (payload == null) {
    commit("DASHBOARD_BRANCH_OFFFICE_ERROR");
    commit("DASHBOARD_BRANCH_OFFFICE_DISPENSING_ERROR");
    return;
  }
  commit("global/IS_LOADING", true, { root: true });
  await DashboardRequest.dashboardBranchOficceDetail(payload)
    .then((response) => {
      let { data } = response;
      let val = {
        unauthorizedProduct: data.stockProductKeyCount || 0,
        authorizedProduct: data.distributionProductKeyCount || 0,
        percentageSupply: data.percentageSupplier || 0,
        idBranchOffice: data.idBranchOffice || 0,
        percentageKey: data.percentageKey || 0,
        productCount: data.totalProductStock || 0,
        warehauseDetail: data.branchOfficesStock || [],
        warehauseDetailStockDistributionBoard: data.branchOfficesStockDistributionBoard || [],
        nameBranchOffice: data.name || payload.nameBranchOffice.name,
        clues: data.branchOfficeKey || payload.nameBranchOffice.branchOfficeKey,
        zone: (() => {
          if (data.zone === 1 || data.zone === 2) {
            return data.zone === 1 ? "RURAL" : "URBANA";
          } else if (payload.nameBranchOffice.zone === 1 || payload.nameBranchOffice.zone === 2) {
            return payload.nameBranchOffice.zone === 1 ? "RURAL" : "URBANA";
          } else {
            return "SIN ZONA";
          }
        })(),
      };
      commit(
        "global/LEVEL_BRANCH_SELECT",
        payload.selectBranches ? payload.selectBranches : 0,
        { root: true }
      );
      commit("DASHBOARD_BRANCH_OFFFICE_SUCCESS", val);
      commit("global/IS_LOADING", false, { root: true });
      commit("global/TAP_SHOW", 2, { root: true });
    })
    .catch((error) => {
      console.log(error);
      commit("DASHBOARD_BRANCH_OFFFICE_ERROR");
      commit("global/IS_LOADING", false, { root: true });
    });
};

export const dashboardBranchOficceDispencingAction = async (
  { commit },
  payload
) => {
  commit("global/IS_LOADING", true, { root: true });
  await DashboardRequest.dashboardBranchOficceDispensing(payload.data)
    .then((response) => {
      let { data } = response;
      let val = {
        cluesKey: payload.item.productKey,
        nameProduct: payload.item.description,
        dispensingData: data,
      };
      commit("DASHBOARD_BRANCH_OFFFICE_DISPENSING_SUCCESS", val);
      commit("global/IS_LOADING", false, { root: true });
    })
    .catch((error) => {
      console.log(error);
      commit("DASHBOARD_BRANCH_OFFFICE_DISPENSING_ERROR");
      commit("global/IS_LOADING", false, { root: true });
    });
};

export const dashboardBranchDetailAction = async ({ commit }, payload) => {
  commit("global/IS_LOADING", true, { root: true });
  await DashboardRequest.dashboardDetail(payload)
    .then((response) => {
      let { data } = response;
      let val = {
        shipments: data.receptions,
        medicalPresciptions: data.medicalPrescriptions,
        dispensingProducts: data.dispensingProducts,
      };
      commit("DASHBOARD_BRANCH_OFFFICE_DETAIL_SUCCESS", val);
      commit("global/IS_LOADING", false, { root: true });
    })
    .catch((error) => {
      console.log(error);
      commit("DASHBOARD_BRANCH_OFFFICE_DETAIL_ERROR");
      commit("global/IS_LOADING", false, { root: true });
    });
};
