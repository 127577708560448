/**
 * @author Alan Saucedo Colin
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";
import { instance } from "@/core/ApiConfig.js";

const LIST_ALL_REMMISIONS_BY_BRANCH = "rmsvc/rm06";

const GET_LIST_INVOICE = "rmsvc/list-all-invoice"


export default class RemissionRequest {

  static listAllRemmisionByBranch(data) {
    return instance.post(LIST_ALL_REMMISIONS_BY_BRANCH, data);
  }

  static listAllRemmision(data) {
    return axios.post(
      ServicePath.REMMISIONS_GET_WITH_ALL_PRODUCTS_REMMISION,
      data
    );
  }

  static updateProductRemmision(data) {
    return axios.post(ServicePath.UPDATE_PRODUCT_REMMISION_BY_STOCK, data);
  }

  static updateRemmision(data) {
    return axios.post(ServicePath.UPDATE_REMMISION, data);
  }

  static addProductInRemmision(data) {
    return axios.post(ServicePath.REMMISIONS_ADD_PRODUCT_IN_REMMISION, data);
  }

  static listAllChangeRemmisions(data) {
    return axios.post(ServicePath.REMMISIONS_LIST_CHANGE, data);
  }

  static changeRemmision(data) {
    return axios.post(ServicePath.REMMISIONS_CHANGE, data);
  }



  static remmisionGetWithProductsRemmision(data) {
    return axios.post(ServicePath.REMMISIONS_GET_WITH_PRODUCTS_REMMISION, data);
  }

  /* Remission [NEW]*/

  static addRemission(data) {
    return axios.post(ServicePath.CREATE_REMMISION, data);
  }

  static getInfoRemission(data) {
    return axios.post(ServicePath.GET_REMISSION_INFO, data);
  }

  static getProductRemission(data) {
    return axios.post(ServicePath.GET_REMISSION_PRODUCTS, data);
  }

  static getRemissionExistent(data) {
    return axios.post(ServicePath.GET_REMISSION_EXISTENT, data);
  }

  static getListInvoice() {
    return instance.get(GET_LIST_INVOICE);
  }


}
