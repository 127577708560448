import { DashboardRequest } from "@/other/core";
import { SwalMessage } from "@/other/utils/SwalAlert";
/* ABASTO - ACTIONS */
export const dashboardDetailAction = async ({ commit }, payload) => {
  commit("DASHBOARD_DETAIL_START");
  commit("global/IS_LOADING", true, { root: true });
  await DashboardRequest.dashboardDetail({ payload })
    .then((response) => {
      let { data } = response;
      let val = {
        shipmentCount: data.shipments,
        prescriptionCount: data.medicalPrescriptions,
        pieceCount: data.dispensingProducts,
      };
      commit("DASHBOARD_DETAIL_SUCCESS", val);
      commit("global/IS_LOADING", false, { root: true });
    })
    .catch((error) => {
      console.log(error);
      SwalMessage.fire({
        icon: "error",
        title: error,
      });
      commit("DASHBOARD_DETAIL_ERROR");
      commit("global/TAP_SHOW", 0, { root: true });
      commit("global/IS_LOADING", false, { root: true });
    });
};

export const dashboardDetailChartAction = async ({ commit }, payload) => {
  commit("global/IS_LOADING", true, { root: true });
  await DashboardRequest.dashboardDetailChart(payload)
    .then((response) => {
      let { data } = response;
      data.jurisdictionsPercentage.sort((a, b) => {
        return a.jurisdictionId - b.jurisdictionId;
      });
      let val = {
        percentageList: data.jurisdictionsPercentage,
        minPercentaje: {
          jurisdiction: data.min.jurisdictionName,
          percentage: data.min.percentage,
        },
        maxPercentaje: {
          jurisdiction: data.max.jurisdictionName,
          percentage: data.max.percentage,
        },
        generalAverage: data.generalAverage,
      };
      commit("DASHBOARD_DETAIL_CHART_SUCCESS", val);
      commit("global/TAP_SHOW", 0, { root: true });
      commit("global/IS_LOADING", false, { root: true });
    })
    .catch((error) => {
      console.log(error);
      /*   SwalMessage.fire({
          icon: "error",
          title: error,
        }); */
      commit("DASHBOARD_DETAIL_CHART_ERROR");
      commit("global/TAP_SHOW", 0, { root: true });
      commit("global/IS_LOADING", false, { root: true });
    });
};

export const dashboardBranchOficceDispensingTableAction = async ({
  commit,
}) => {
  await DashboardRequest.dashboardBranchOficceDispensingTable()
    .then((response) => {
      let { data } = response;
      let dataList = [];
      data.map((table) => {
        let val = {
          mes: table.month,
          anio: table.yearDispensing,
          quantityS: table.medicalPrescriptionCount,
          quantityD: table.productsDispensingCount,
        };
        dataList.push(val);
      });
      let payload = {
        dataList: dataList,
      };
      commit("DASHBOARD_DETAIL_TABLE_SUCCESS", payload);
    })
    .catch((error) => {
      console.log(error);
      commit("global/IS_LOADING", false, { root: true });
    });
};