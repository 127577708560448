/**
 * @author Jesus TC
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath, UtilFront } from "@/core";

export default class QuizRequest {

    static listQuiz() {
        return axios.post( ServicePath.QUIZ_PRESCRIPTION );
    }

    static saveAnswersServer( data ) {
        return axios.post( ServicePath.QUIZ_SAVE_ANSWERS, data  ); 
    }


}