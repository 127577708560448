import { UtilFront } from "@/core";
import axios from "axios";
import { SupplierRequest } from "@/core/request";

import { SECURE_ENDPOINT } from "@/core/AppConfiguration";
const REQUISITION_LIST_BY_BRANCH_OFFICE = SECURE_ENDPOINT + "rqgsvc/rqg08";

export const GET_PRODUCTS = async (state, val) => {
  state.listProduct = val;
};

export const SET_FISCAL_FOUND = (state, val) => {
  state.listFiscalFound = val;
};

export const SET_REQUISITION = (state, val) => {
  let requisitionGovList = [];
  let user = UtilFront.getDataUser();
  if (val != null) {
    axios
      .post(REQUISITION_LIST_BY_BRANCH_OFFICE, {
        userBranchOfficeDTO: {
          idBranchOffice: val,
          idUser: user.idUser,
        },
      })
      .then((response) => {
        let { success, data } = response.data.data;
        if (success) {
          for (var [index, value] of Object.entries(data)) {
            index;
            let requistionItem = {
              text: value.requisitionKey + " - " + "(" + value.biddingKey + ")",
              value: value.idRequisition,
            };
            requisitionGovList.push(requistionItem);
          }
          state.listRequisition = requisitionGovList;
        }
      });
  }
};

export const SET_SUPPLIER = (state) => {
  SupplierRequest.listAll().then((response) => {
    let { data } = response.data.data;
    if (data) {
      state.listSupplier = data.objects.map((obj) => {
        return {
          value: obj.idSupplier,
          text: obj.name,
        };
      });
    }
  });
};

export const SET_REMISSION_EXISTENT = (state, val) => {
  state.remissionExistent = val;
};
