/**
 * @author Alan Saucedo
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath, UtilFront } from "@/core";

export default class ShipmentsRequest {
  static addShipment(data) {
    return axios.post(ServicePath.ADD_SHIPMENT, UtilFront.setUserID(data));
  }

  static updateShipment(data) {
    return axios.post(ServicePath.SHIPMENT_UPDATE, data);
  }

  static findAllShipmentsByUser(data) {
    return axios.post(ServicePath.SHIPMENT_LIST_ALL_BY_USER, data);
  }

  static findAllShipmentsByStatus(data) {
    return axios.post(ServicePath.SHIPMENT_LIST_ALL_BY_USER, data);
  }

  static findDetailShipments(data) {
    return axios.post(ServicePath.SHIPMENT_FIND_DETAIL, data);
  }

  static listAllProductsByShipmentAndOrder(data) {
    return axios.post(ServicePath.SHIPMENT_FIND_DETAIL_BY_ORDER, data);
  }

  static updateDestination(data) {
    data["userID"] = UtilFront.getIdUser();
    return axios.post(ServicePath.SHIPMENT_UPDATE_DESTINATION, data);
  }

  static shipmentsReturn(data) {
    return axios.post(ServicePath.SHIPMENTS_RETURNS, data);
  }

  static shipmentsDetailReturn(data) {
    return axios.post(ServicePath.SHIPMENTS_DETAIL_RETURNS, data);
  }

  static addShipmentWithOrderTypeDecrease(data) {
    return axios.post(ServicePath.SHIPMENTS_WITH_ORDER_TYPE_DECREASE, data);
  }

  static addShipmentWithOrderTypeStartingOrder(data) {
    return axios.post(
      ServicePath.SHIPMENTS_WITH_ORDER_TYPE_STARTING_ORDER,
      data
    );
  }

  static validateShipmentAndOrder(data) {
    return axios.post(ServicePath.SHIPMENTS_VALIDATE_ORDER, data);
  }

  static finishDecrease(data) {
    return axios.post(ServicePath.SHIPMENTS_FINISH_DECREASE, data);
  }

  static finishStartingOrder(data) {
    return axios.post(ServicePath.SHIPMENTS_FINISH_STARTING_ORDER, data);
  }

  static listAllShipmentsByType(data) {
    return axios.post(ServicePath.SHIPMENTS_LIST_ALL_BY_TYPE, data);
  }

  static changeShipmentStatus(data) {
    return axios.post(ServicePath.SHIPMENTS_CHANGE_STATUS, data);
  }

  static findAllShipmentsByStatusShipping(data) {
    return axios.post(ServicePath.SHIPMENTS_LIST_ALL_BY_STATUS_SHIPPING, data);
  }

  static shipmentGenerateTransfer(data) {
    return axios.post(ServicePath.SHIPMENTS_GENERATE_TRANSFER, data);
  }

  static listAllShipmentsWithTypeAndStatus(data) {
    return axios.post(ServicePath.SHIPMENT_LIST_ALL_WITH_STATUS, data);
  }

  static shipmentsMovementHistory(data) {
    return axios.post(ServicePath.SHIPMENTS_MOVEMENT_HISTORY, data);
  }

  static findAllShipmentsByUserKey(data) {
    let shipmentsArray = [];
    this.findAllShipmentsByUser(data).then((response) => {
      for (var [index, value] of Object.entries(response.data.data.objects)) {
        let shipmentItem = {
          idShipment: value.idShipment,
          destination: value.destination,
          destinationKey: value.destinationKey,
          orderId: value.orderId,
          dateshipment: value.dateshipment,
        };
        shipmentsArray.push(shipmentItem);
      }
    });
    return shipmentsArray;
  }

  static findAllShipmentsWithStatus(idBranchOffcie) {
    return axios.post(ServicePath.SHIPMENT_LIST_ALL_WITH_STATUS, {
      id: idBranchOffcie,
    });
  }

  static listSupplierByFinancing(data) {
    return axios.post(ServicePath.SUPPLIER_LIST_BY_FINANCING, data);
  }
}
