import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-outline-success mr-2",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

const defaultOptions = {
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
};

export const SwalInfo = swalWithBootstrapButtons.mixin({
  icon: "info",
  title: "",
  text: "",
  confirmButtonText: '<i class="feather icon-check mr-1 mr-1"></i> Aceptar',
});

export const SwalMessage = Swal.mixin({
  icon: "",
  title: "",
  ...defaultOptions,
});

export default {
  SwalMessage,
};
