/**
 * @author Jesus Teran Cervantes
 * @company Erillam Healthcare
 * @version 1.0
 */
import { instance } from "@/core/ApiConfig.js";
/* Configuration PATH - GLOBAL */
const GET_ALL_BRANCH_OFFICE = "brofsvc/branch-offices";

/* Configuration PATH - Configuración de Tablero de Control */
const GET_ALL_USER_SUPPLIED = "configuration-dashboard/users/supply";
const UPDATE_USER_DASHBOARD_STATUS = "configuration-dashboard/users/available";
/* Configuration PATH - Tablero de Control Abasto  */
const GET_ALL_BRANCH_OFFICE_DASHBOARD_ABASTO =
  "configuration-dashboard/branch-offices/available";
const UPDATE_BRANCH_OFFICE_DASHBOARD_ABASTO =
  "configuration-dashboard/branch-offices";
const GET_ALL_DASHBOARDS_OFFICE_USER =
  "configuration-dashboard/users/branch-offices";
const SET_USER_DASHBOARD_ABASTO = "configuration-dashboard/users";
/* Configuration PATH - Tablero de Control Estadistica  */
const GET_ALL_DASHBOARDS_STATISTICS =
  "configuration-dashboard/users/statistics";

export default class ConfigurationRequest {
  /* Configuration PATH - GLOBAL */
  static getAllFindBranchOffice = () => {
    return instance.get(GET_ALL_BRANCH_OFFICE);
  };

  /* Configuración de Tablero de Control */
  static getAllUserSupplied = (pages) => {
    return instance.post(GET_ALL_USER_SUPPLIED, pages);
  };

  static updateDasboardStatusUser = async (data) => {
    return instance.put(UPDATE_USER_DASHBOARD_STATUS, data);
  };

  /* Tablero de Control Abasto */

  static getBranchOfficeUser = async (data) => {
    return instance.post(GET_ALL_DASHBOARDS_OFFICE_USER, data);
  };

  static setUserDashboardAbasto = async (data) => {
    return instance.post(SET_USER_DASHBOARD_ABASTO, data);
  };

  static updateUserDashboardAbasto = async (data) => {
    return instance.put(SET_USER_DASHBOARD_ABASTO, data);
  };

  static getAllBranchOfficeAbasto = () => {
    return instance.get(GET_ALL_BRANCH_OFFICE_DASHBOARD_ABASTO);
  };

  static updateBranchOfficeAbasto = async (data) => {
    return instance.put(UPDATE_BRANCH_OFFICE_DASHBOARD_ABASTO, data);
  };

  /* Tablero de Control Estadistica  */
  static getAllUserDashboardStatistics = (pages) => {
    return instance.post(GET_ALL_DASHBOARDS_STATISTICS, pages);
  };
}
