export default {
  password: "contraseña",
  password_confirmation: "confirmar contraseña",
  name: "nombre",
  user: "usuario",
  email: "correo electrónico",
  email_confirmation: "cofirmar correo electrónico",
  lastname: "apellido(s)",
  phone: "télefono",
  birth_date: "fecha de nacimiento",
  gender: "genero",
  manager: "encargado",
  charge: "cargo",
  department: "departamento",
  role: "rol",
  branch: "sucursal",
  order: "pedido",
  bidding: "licitación",
  remission_ref: "referencia entrada",
  bill: "factura",
  supplier: "proveedor",
  product: "producto",
  lot: "lote",
  quantity: "cantidad",
  cost_unity: "costo unitario",
  expiry_date: "fecha de caducidad",
  source_of_financing: "fuente de financiamiento",
  drivers_name: "nombre del conductor",
  plate_number: "numero de placa",
  economic_number: "numero economico",
  tag: "etiqueta",
  amount_received: "cantidad recibida",
  comment: "comentario",
  type_incidence: "tipo de incidencia",
  product_supplier: "proveedor de producto",
  reason: "razón",
  professiona_license: "cédula profesional",
  motive: "motivo",
  clues: "clues",
  warehouse_ID: "ID almacén",
  type_branch: "tipo sucursal",
  jurisdiction: "jurisdicción",
  street: "calle",
  outside_number: "número exterior",
  inside_number: "número interior",
  colonia: "colonia",
  zip_code: "código postal",
  city: "municipio",
  road: "vialidad",
  location: "localidad",
  description: "descripción",
  acronym: "siglas",
  categories: "categorías",
  causes: "causes",
  unit_of_measure: "unidad de medida",
  rfc: "RFC",
  folio: "folio de la receta",
  number_file: "N° Expediente",
  date_prescription: "fecha de la receta",
  date_dispensing: "fecha de dispensación",
  curp: "curp",
  source_of_financing: "fuente financiamiento",
  amount_prescribed: "cantidad recetada",
  amount_denied: "cantidad negada",
  actual_quantity: "cantidad real",
  note: "observación",
  location_warehouse: "ubicación",
  type_request: "tipo de solicitud",
  ref_request: "referencia solicitud ( aprovisionamiento )",
  products: "productos",
  branch_main: "sucursal principal",
  remissionType: "tipo de entrada",
  observation: "observaciones",
  doctor: "personal, departamento y puesto",
  doctor_:"doctor",
  procurement: "aprovisionamiento",
  level_dashboard: "nivel de tlabero de control",
  dasboard_distribution_board_main: "cuandro de distribución principal",
  file: "archivo",
  movement_motive: "motivo de movimiento",
  reference: "folio/referencia",
  dispensing_type: "tipo de receta",
  current_year:"año en curso"
};
