import { DashboardRequest } from "@/other/core";

export const dashboardNivelUserAction = async ({ commit }, payload) => {
  commit("global/IS_LOADING", true, { root: true });
  await DashboardRequest.dashboardNivelUser(payload)
    .then((response) => {
      let { data } = response;
      commit("USER_NIVEL_SUCCESS", data);
      commit("global/IS_LOADING", false, { root: true });
    })
    .catch((error) => {
      console.log(error);
      commit("global/IS_LOADING", false, { root: true });
      commit("USER_NIVEL_ERROR");
    });
};

export const getDashboardList = async ({ commit }, payload) => {
  commit("IS_LOADING", true);
  await DashboardRequest.listDashboardUser(payload)
    .then((response) => {
      let { data } = response;
      commit("LIST_DASHBOARD_USER_SUCCESS", data);
      commit("IS_LOADING", false);
    })
    .catch((error) => {
      console.log(error);
      commit("IS_LOADING", false);
      commit("LIST_DASHBOARD_USER_ERROR");
    });
};

export const getDashboardStatisticUrl = async ({ commit }, payload) => {
  commit("IS_LOADING", true);
  await DashboardRequest.getUrlDashboardStatistics(payload)
    .then((response) => {
      let { data } = response;
      commit("SET_URL_DASHBOARD", data);
      commit("IS_LOADING", false);
    })
    .catch((error) => {
      console.log(error);
      commit("IS_LOADING", false);
      commit("SET_URL_DASHBOARD", "");
    });
};
