/**
 * @author Jesus Teran
 * @company Erillam Healthcare
 * @version 1.0
 */

 import axios from "axios";
 import { ServicePath, UtilFront } from "@/core";
 
 export default class StockRequest {

     static updateStockQuantity(data) {
         return axios.post(ServicePath.STOCK_UPDATE_QUANTITY,data);
     }
 
     static updateStockQuantityTransfer(data) {
         return axios.post(ServicePath.STOCK_UPDATE_QUANTITY_TRANSFER,data);
     }
 
     static listAllTransfer(data) {
         return axios.post(ServicePath.STOCK_LIST_ALL_TRANSFERS,data);
     }
 
     static listAllStockWithExistenceByBranchOffice( data ) {
         return axios.post(ServicePath.GET_ALL_STOCK_FROM_USER_BRANCH, data);
     }
 
     static listAllStockByProductAndBranchOffice(data) {
         return axios.post(ServicePath.STOCK_BY_PRODUCTS_AND_BRANCH_OFFICE, data);
     }
 
     static listAllStockByProductAndBranchOfficeExpired(data) {
         return axios.post(ServicePath.STOCK_BY_PRODUCTS_AND_BRANCH_OFFICE_EXPIRED, data);
     }
     
     static getDetailFull(idStock) {
         return axios.post(ServicePath.STOCK_GET_FULL_DETAIL, {id:idStock});
     }
 
     static listAllStockExpired(idBranchOffice, showAll) {
         let params = { userID: UtilFront.getIdUser(), id: idBranchOffice, showAll: showAll };
         return axios.post(ServicePath.STOCK_LIST_ALL_EXPIRED, params);
     }
 
     static stockObservation(data) {
         return axios.post(ServicePath.STOCK_OBSERVATIONS, data);
     }
 
     static updateStockWithOrderStock(data) {
         return axios.post(ServicePath.STOCK_UPDATE_WITH_ORDER_STOCK, data);
     }
 
     static updateStockPrescription(data) {
         return axios.post(ServicePath.STOCK_UPDATE_PRESCRIPTION, data);
     }
 
     static changeProductStock(data) {
         return axios.post(ServicePath.PRODUCTO_REMMISION_CHANGE_PRODUCT, data);
     }

     static listAllNonConform(data) {
        return axios.post(ServicePath.STOCK_NON_CONFORME, data);
    }
 
 
 }