export default () => ({
  distributionBoard: {
    authorizedProduct: 0,
    unauthorizedProduct: 0,
  },
  generalAverage: {
    percentageSupply: 0,
    percentageKey: 0,
  },
  productCount: 0,
  warehauseDetail: [],
  warehauseDetailStockDistributionBoard: [],
  modelBranchOffice: {
    nameBranchOffice: "",
    clues: "",
    zone: "",
  },
  cluesKey: "",
  nameProduct: "",
  dispensingData: [],
  idBranchOffice: 0,
  modelDetails: {
    shipments: 0,
    medicalPresciptions: 0,
    dispensingProducts: 0,
  },
});
