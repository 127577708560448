export const DASHBOARD_JURISDICTION_DETAIL_START = (state) => {
  state.modelDetails.shipmentCount = 0;
  state.modelDetails.prescriptionCount = 0;
  state.modelDetails.pieceCount = 0;
};

export const DASHBOARD_JURISDICTION_DETAIL_SUCCESS = (state, val) => {
  state.modelDetails.shipmentCount = val.shipmentCount;
  state.modelDetails.prescriptionCount = val.prescriptionCount;
  state.modelDetails.pieceCount = val.pieceCount;
};

export const DASHBOARD_JURISDICTION_DETAIL_ERROR = (state) => {
  state.modelDetails.shipmentCount = 0;
  state.modelDetails.prescriptionCount = 0;
  state.modelDetails.pieceCount = 0;
  state.modelJurisdiction.currentJurisdiction = "";
};

export const DASHBOARD_CHART_JURISDICTION_DETAIL_START = (state) => {
  state.modelJurisdiction.minPercentage = {};
  state.modelJurisdiction.maxPercentage = {};
  state.modelJurisdiction.generalAverage = {};
};

export const DASHBOARD_CHART_JURISDICTION_DETAIL_SUCCESS = (state, val) => {
  state.modelJurisdiction.minPercentage = val.minPercentage;
  state.modelJurisdiction.maxPercentage = val.maxPercentage;
  state.modelJurisdiction.generalAverage = val.generalAverage;
  state.modelJurisdiction.minPercentageHospital = val.minPercentageHospital;
  state.modelJurisdiction.maxPercentageHospital = val.maxPercentageHospital;
  state.modelJurisdiction.currentJurisdictionID = val.currentJurisdictionID;
  state.modelJurisdiction.currentJurisdiction = val.currentJurisdiction;
};

export const DASHBOARD_CHART_JURISDICTION_DETAIL_ERROR = (state) => {
  state.modelJurisdiction.minPercentage = {
    branchOffice: "SIN VALORES",
    percentage: 0,
  };
  state.modelJurisdiction.maxPercentage = {
    branchOffice: "SIN VALORES",
    percentage: 0,
  };
  state.modelJurisdiction.minPercentageHospital = {
    branchOffice: "SIN VALORES",
    percentage: 0,
  };
  state.modelJurisdiction.maxPercentageHospital = {
    branchOffice: "SIN VALORES",
    percentage: 0,
  };
  state.modelJurisdiction.generalAverage = {
    jurisdiction: "SIN VALOR",
    average: 0,
  };
};

export const DASHBOARD_BY_JURISDICTION_TABLE_START = (state) => {
  state.modelJurisdiction.listJurisdictions = [];
};
export const DASHBOARD_BY_JURISDICTION_TABLE_SUCCESS = (state, val) => {
  state.modelJurisdiction.listJurisdictions = val;
};
export const DASHBOARD_BY_JURISDICTION_TABLE_ERROR = (state) => {
  state.modelJurisdiction.listJurisdictions = [];
};
