<template>
  <div v-if="notifier.notifying" class="col-12">
    <div class="message-alert">
      <v-alert
        :icon="iconAlert"
        dense
        :dismissible="false"
        text
        :type="typeAlert"
        border="left"
      >
        <strong>{{ notifier.title }}</strong> {{ $t(notifier.message) }}
        <button
          type="button"
          @click="notifier.notifying = false"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notifier: {
        notifying: false,
        type: "",
        message: "",
        title: "",
      },
    };
  },
  computed: {
    typeAlert() {
      return this.notifier.type == 1
        ? "success"
        : this.notifier.type == 2
        ? "error"
        : this.notifier.type == 3
        ? "warning"
        : "warning";
    },
    iconAlert() {
      return this.notifier.type == 1
        ? "mdi mdi-check-circle"
        : this.notifier.type == 2
        ? "fa fa-exclamation-triangle"
        : this.notifier.type == 3
        ? "mdi mdi-alert-circle"
        : "default";
    },
  },
  methods: {
    notification(type, message) {
      if (type == "success") {
        this.notifier.type = 1;
        this.notifier.title = "¡Éxito!";
        this.$toast.success(
          this.$t(message) || "¡La acción se ha realizado correctamente!."
        );
        this.notifier.message =
          message || "¡La acción se ha realizado correctamente!.";
      } else if (type == "error") {
        this.notifier.type = 2;
        this.$toast.error(
          this.$t(message) || "Ups ¡Ocurrio un error interno! Intenta de nuevo."
        );
        this.notifier.title = "¡Error!";
        this.notifier.message =
          message || "Ups ¡Ocurrio un error interno! Intenta de nuevo.";
      } else {
        this.notifier.type = 3;
        this.notifier.title = "¡Advertencia!";
        this.$toast.warning(
          this.$t(message) ||
            "Algo salio mal, verifiqué la información ingresada."
        );
        this.notifier.message =
          message || "Algo salio mal, verifiqué la información ingresada.";
      }
      this.notifier.notifying = true;
      setTimeout(() => {
        this.notifier.notifying = false;
      }, 7000);
    },
    reloadComponent(type, message) {
      /* console.log(type, message); */
      if (type == 0) {
        this.notifier.notifying = false;
      } else if (type == "internal" || (type == "error" && message.undefined)) {
        this.notification(
          "error",
          "Ups ¡Ocurrio un error interno! Intenta de nuevo."
        );
      } else {
        this.notification(type, message);
      }
    },
    reloadComponentClose() {
      this.notifier.notifying = false;
    },
  },
};
</script>
