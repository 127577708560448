/**
 * @author Jesus Teran
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";


export default class DistributionRequest {

    static listDistribution(data) {
        return axios.post(ServicePath.DISTRIBUTION_BOARD_LIST_ALL, data)
    }

    static createBoardDistribution(data) {
        return axios.post(ServicePath.CREATE_BOARD_DISTRIBUTION, data)
    }

    static updateBoardDistributionInfo(data) {
        return axios.post(ServicePath.UPDATE_BOARD_DISTRIBUTION_INFO, data)
    }

    static updateProductBoardDistribution(data) {
        return axios.post(ServicePath.UPDATE_BOARD_DISTRIBUTION_PRODUCTS, data)
    }

    static updateBranchOfficeBoardDistribution(data) {
        return axios.post(ServicePath.UPDATE_BOARD_DISTRIBUTION_BRANCH_OFFICE, data)
    }

    static getBranchOfficeDistribution(data) {
        return axios.post(ServicePath.DISTRIBUTION_GET_BRANCH_OFFICE, data)
    }

    static getProductsDistribution(data) {
        return axios.post(ServicePath.DISTRIBUTION_GET_PRODUCTS, data)
    }

}