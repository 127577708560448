/**
 * @author Fernando FH
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";

export default class IncidenceOrderRequest {
  static listAll(data) {
    return axios.post(
      ServicePath.INCIDENTE_ORDER_ALL_BY_BRANCH_OFFICE_FROM_ORDER_STOCK,
      data
    );
  }
  static listAllOut(idBranchoffice) {
    return axios.post(ServicePath.INCIDENTE_ORDER_ALL_BY_BRANCH_OFFICE_ORIGIN, {
      id: idBranchoffice,
    });
  }
  static listTypesIncidence() {
    return axios.post(ServicePath.INCIDENTE_ALL_TYPES);
  }
  static add(object) {
    return axios.post(ServicePath.INCIDENTE_ADD, object);
  }
  static update(object) {
    // return axios.post(ServicePath.ROL_UPDATE, object);
  }
  static updateStatus(object) {
    return axios.post(ServicePath.INCIDENTE_ORDER_UPDATE_STATUS, object);
  }
}
