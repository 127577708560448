import { extend, configure } from "vee-validate";
import {
  required,
  email,
  min,
  max,
  confirmed,
  numeric,
  double,
  max_value,
  alpha_num,
  min_value,
  excluded,
  alpha_dash,
  regex,
  alpha_spaces,
  alpha,
  size,
  ext
} from "vee-validate/dist/rules";

import { curp, file_pdf } from "./rules";

import i18n from "./i18n";

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  },
});

// Install required rule and message.
extend("required", required);
// Install email rule and message.
extend("email", email);
// Install min rule and message.
extend("min", min);
// Install max rule and message.
extend("max", max);
// Install confirmed rule and message.
extend("confirmed", confirmed);
// Install numeric rule and message.
extend("numeric", numeric);
// Install double rule and message.
extend("double", double);
// Install max_value rule and message.
extend("max_value", max_value);
// Install alpha_num rule and message.
extend("alpha_num", alpha_num);
// Install min_value rule and message.
extend("min_value", min_value);
// Install min_value rule and message.
extend("excluded", excluded);
// Install alpha_dash rule and message
extend("alpha_dash", alpha_dash);
// Install alpha_dash rule and message
extend("regex", regex);
// Install alpha_spaces rule and message
extend("alpha_spaces", alpha_spaces);
// Install alpha_spaces rule and message
extend("alpha", alpha);
// Install size rule and message
extend("size", size);
// Install ext rule and message
extend("ext", ext);

/* Personal Rules */
// Install curp rule and message
extend("curp", curp);
// Install curp rule and message
extend("file_pdf", file_pdf);