
export const UPDATE_ID_PRESCRIPTION = ( state, val ) => {
    state.idPrescription = val;
}

export const UPDATE_ID_ORDER = ( state, val ) => {
    state.idOrder = val;
}

export const UPDATE_IS_UPDATE = ( state, val ) => {
    state.isUpdate = val;
}

export const UPDATE_SURVEY_COUNT = ( state, val ) => {
    state.surveyCount = val;
}

export const UPDATE_TOTAL_SURVEY = ( state, val ) => {
    state.totalSurveys = val;
}


