/**
 * @author Fernando FH
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";

export default class RolRequest {
    static listAll() {
        return axios.post(ServicePath.ROL_LIST_ALL);
    }
    static listAllWithPermmisions(data) {
        return axios.post(ServicePath.ROL_LIST_ALL_WITH_PERMMISIONS, data);
    }
    static listAllPermmisions() {
        return axios.post(ServicePath.ROL_LIST_ALL_PERMMISIONS);
    }
    static addPermission(object) {
        return axios.post(ServicePath.ROL_ADD, object);
    }
    static updatePermission(object) {
        return axios.post(ServicePath.ROL_UPDATE, object);
    }
}