import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSession from "vue-session";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vuetify from "@/plugins/vuetify";
import VueCryptojs from "vue-cryptojs";
import Loading from "vue-loading-overlay";
import Toast from "vue-toastification";
import VCalendar from "v-calendar";
import "vue-toastification/dist/index.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "@/assets/scss/style.scss";
import "@/assets/css/app.css";
import "@/assets/css/top-bar.css";
import "@/assets/css/bootstrap.css";
import i18n from "@/tools/i18n";
import "@/tools/vee-validate";
import CodeMirror from "@/plugins/CodeMirror";

// Alert Component
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
//Button Print Component
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
//Validition Components
import { ValidationObserver, ValidationProvider } from "vee-validate";
import 'moment/locale/es';

//SwalConfirm Function
import { SwalConfirm } from "./core/SwalAlert";
//UtilFront Functions
import { UtilFront } from "@/core";
//Permission Constants
import { PermissionConstants } from "@/core";



// Alert Component
Vue.component('AlertMessageComponent',AlertMessageComponent);
//Button Print Component
Vue.component('ButtonPrintComponent',ButtonPrintComponent);
//Validation Components
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('CodeMirror',CodeMirror);

//SwalConfirm Function
Vue.prototype.$SwalConfirm = SwalConfirm;
//UtilFront Functions
Vue.prototype.$UtilFront = UtilFront;
//Permission Constants
Vue.prototype.$PermissionConstants = PermissionConstants;


Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSession);
Vue.use(VueCryptojs);
Vue.use(Loading);
Vue.use(Toast, {
  pauseOnFocusLoss: false,
  maxToasts: 5,
  position: "bottom-right",
});

Vue.use(VCalendar, {
  componentPrefix: "vc",
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
