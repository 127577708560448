import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import auth from "./modules/auth";
import dispensing from "./modules/dispensing";
import locales from "./modules/locales";
import remission from "./modules/remission";
/* Dashboard */
import supply from "./modules/dashboard/supply";
import jurisdiction from "./modules/dashboard/jurisdiction";
import global from "./modules/dashboard/global";
import branchOffice from "./modules/dashboard/branchOffice";

const store = new Vuex.Store({
  modules: {
    // Session
    auth: auth,
    // Dispensing
    dispensing: dispensing,
    // Remission
    remission: remission,
    // Language
    locales: locales,
    // Dashboard
    supply: supply,
    jurisdiction: jurisdiction,
    branchOffice: branchOffice,
    global: global,
  },
});

export default store;
