<template>
  <div class="container-fluid bg sc">
    <div v-if="loadingPageLogin" class="spinner loading-center"></div>
    <transition name="fade">
      <div v-if="!loadingPage" class="row">
        <div
          class="
            col-md-12 col-sm-12
            bg-image
            col-lg-6 col-xs-12
            d-none d-md-flex
          "
        >
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6 bg-image-login p-0">
          <!-- <div class="content">
            <ProvinceCommon />
          </div> -->
          <div class="login d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div
                  class="col-lg-8 col-xl-6 col-md-6 col-sm-8 col-xs-12 mx-auto"
                >
                  <div>
                    <validation-observer ref="observer" v-slot="{ invalid }">
                      <form action @submit.prevent="login">
                        <div class="form-head">
                          <a class="logo"
                            ><img
                              src="@/assets/images/logo_.svg"
                              class="img-fluid"
                              alt="logo"
                          /></a>
                        </div>
                        <div class="form-group mt-4">
                          <label for="password">{{ $t("login.user") }}</label>
                          <validation-provider name="user" rules="required">
                            <input
                              :value="user"
                              @input="user = $event.target.value"
                              type="text"
                              class="form-control"
                              id="username"
                              :placeholder="$t('login.email')"
                              required
                            />
                          </validation-provider>
                        </div>

                        <div class="form-group">
                          <label for="password"
                            >{{ $t("login.password") }}
                          </label>
                          <validation-provider name="password" rules="required">
                            <input
                              :value="password"
                              @input="password = $event.target.value"
                              type="password"
                              class="form-control"
                              id="password"
                              autocomplete
                              :placeholder="$t('login.password')"
                              required
                            />
                          </validation-provider>
                        </div>
                        <button
                          id="sendLogin"
                          type="submit"
                          class="
                            btn btn-success btn-lg btn-block
                            font-19
                            button
                          "
                          :class="{ 'not-allowed': sending }"
                          :disabled="sending || invalid"
                          :loading="sending"
                        >
                          {{ $t(textLogin) }}
                        </button>
                      </form>
                    </validation-observer>
                  </div>
                  <div class="pt-4">
                    <b-alert v-model="showmessage" variant="danger" dismissible>
                      <b> {{ messageLogin }}</b>
                    </b-alert>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="
                    col-lg-8 col-xl-6 col-md-6 col-sm-8 col-xs-12
                    mx-auto
                    certificado
                  "
                >
                  <div
                    id="DigiCertClickID_zw43CHYg"
                    data-language="es"
                    class="pb-5"
                  ></div>
                </div>
                <div
                  class="
                    col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12
                    footer
                  "
                >
                  <footer>
                    <v-col class="text-center">
                      © 2017 FMedical {{ $t("login.copyright") }}
                    </v-col>
                    <!--  <LocaleSwitcher /> -->
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { LoginRequest } from "@/core/request";
import { UtilFront } from "@/core";
import ProvinceCommon from "@/common/img/ProvinceCommon";
import LocaleSwitcher from "@/components/locales/LocaleSwitcher";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { POSITION } from "vue-toastification";

export default {
  data() {
    return {
      user: "",
      password: "",
      loginResponse: [],
      messageBlock: false,
      sending: false,
      textLogin: "login.log_in",
      message: "",
      loadingPage: true,
      loadingPageLogin: false,
      showmessage: false,
      messageLogin: "",
    };
  },
  methods: {
    async getAttempt() {
      /* Comentar si se descomenta getAttempts */
      this.$toast.error("Usuario y/o contraseña son incorrectos.", {
        position: POSITION.BOTTOM_LEFT,
      });

      /*   await LoginRequest.getAttempts({
        email: this.user.trim(),
      })
        .then((response) => {
          if (response.data?.data?.countAttempts) {
            if (response.data?.data?.countAttempts == 3) {
              this.showmessage = true;
              this.messageLogin =
                "Cuenta bloqueada por superar el número máximo de intentos, por favor consulta con un administrador.";
              return;
            }
            this.$toast.error("Usuario y/o contraseña son incorrectos.", {
              position: POSITION.BOTTOM_LEFT,
            });
          } else {
            this.showmessage = true;
            this.messageLogin = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.sending = false;
          this.textLogin = "login.log_in";
        }); */
    },
    catchError(error) {
      console.log(error);
      this.$toast.error("Ocurrio un error interno.");
    },
    async login() {
      try {
        this.sending = true;
        this.showmessage = false;
        this.textLogin = "login.log";
        const response = await LoginRequest.authenticateUser({
          email: this.user.trim(),
          password: this.password.trim(),
        });
        const { code, data: userInfo } = response.data;
        if (code === 200) {
          const dataUser = {
            branchOfficesSimple: userInfo.data.branchOffices.map((obj) => ({
              value: { idBranchoffice: obj.idBranchOffice },
              text: `${obj.name} - (${obj.branchOfficeKey})`,
              status: obj.status,
            })),
            permissions: JSON.parse(userInfo.data.permissions),
            idUser: userInfo.data.idUser,
            name: userInfo.data.name,
            lastname: userInfo.data.lastName,
            email: userInfo.data.email,
            mainBranch: userInfo.data.mainBranch,
          };
          this.$session.start();
          UtilFront.setDataUser(dataUser);

          this.loadingPage = !this.loadingPage;
          this.loadingPageLogin = true;
          setTimeout(() => {
            this.loadingPage = true;
            this.loadingPageLogin = false;
            this.$router.push("/dashboard");
          }, 2000);
        } else {
          this.getAttempt();
        }
      } catch (error) {
        this.catchError(error);
      } finally {
        this.sending = false;
        this.textLogin = "login.log_in";
      }
    },
  },
  computed: {
    eneableSubmit() {
      return this.user != "" || this.password != "";
    },
  },
  beforeCreate() {
    if (this.$session.exists()) {
      this.loadingPage = !this.loadingPage;
      this.loadingPageLogin = true;
      setTimeout(
        () => (
          (this.loadingPage = false),
          (this.loadingPageLogin = false),
          this.$router.push("/dashboard")
        ),
        2000
      );
    }
  },
  created() {
    setTimeout(() => (this.loadingPage = !this.loadingPage), 100);
    this.loadingPageLogin = false;
  },
  components: {
    LocaleSwitcher,
    ValidationProvider,
    ValidationObserver,
    ProvinceCommon,
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  width: 200px; /* Need a specific value to work */
}
.sc {
  overflow: hidden;
}

.loading-center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

.certificado {
  text-align: center;
}

.bg-image-login {
  background: url(../assets/images/quiz/fondo_quiz.jpg);
  background-position: center;
  background-size: cover;
  height: 102vh;
}

.footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 0px;
}

.theme--light.v-footer {
  background-color: #f6f9fc !important;
  width: 100%;
}

.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.form-group {
  text-align: left !important;
}

.button {
  text-transform: uppercase;
  font-weight: bold;
  height: 50px;
  font-family: "Roboto-Regular";
  letter-spacing: 2px;
}

.form-control {
  padding: 0.75rem;
  font-family: "Roboto-Regular";
}

label {
  color: #000000;
  font-family: "Roboto-Regular" !important;
}

input {
  border-radius: 5px;
  font-size: 17px;
  height: 40px;
}

.login,
.image {
  min-height: 100vh;
}

.bg {
  background-color: #fff;
  min-height: 100vh;
}

.bg-image > figure {
  animation: imageAnimation 30s linear infinite 0s;
  backface-visibility: hidden;
  background-size: cover;
  background-position: center center;
  color: transparent;
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
}

.bg-image > figure:nth-child(1) {
  background-image: url("../assets/images/fondos/51.webp");
  background-position: 75% 25%;
}

.bg-image > figure:nth-child(2) {
  animation-delay: 6s;
  background-image: url("../assets/images/fondos/53.webp");
  background-position: 10% 65%;
}

.bg-image > figure:nth-child(3) {
  animation-delay: 12s;
  background-image: url("../assets/images/fondos/13.webp");
  background-position: 50% 10%;
}

.bg-image > figure:nth-child(4) {
  animation-delay: 18s;
  background-image: url("../assets/images/fondos/9.webp");
  background-position: 10% 65%;
}

.bg-image > figure:nth-child(5) {
  animation-delay: 24s;
  background-image: url("../assets/images/fondos/46.webp");
  background-position: 0% 65%;
}

@keyframes imageAnimation {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
  }

  8% {
    animation-timing-function: ease-out;
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

#sendLogin {
  margin-top: 20px;
}

.center-position {
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (min-width: 768px) {
  .img-fluid {
    width: 70%;
    height: 100%;
  }
}
@media (max-width: 768px) {
  .img-fluid {
    width: 70%;
    height: 100%;
  }
}

.form-head {
  text-align: center;
}

/* Animacion de login  */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
