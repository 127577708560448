import {
  SECURE_ENDPOINT,
  UNSECURE_ENDPOINT,
  SECURE_ENDPOINT_QUIZ,
  SECURE_ENDPOINT_REPORTS,
} from "@/core/AppConfiguration";

// import axios from "axios";

// axios.defaults.baseURL = SECURE_ENDPOINT; 

export default class ServicePath {
  //**Permission Service*/
  static PERMISSION_LIST_ALL = SECURE_ENDPOINT + "prnsv/prn01";
  static PERMISSION_ADD = SECURE_ENDPOINT + "prnsv/prn02";
  static PERMISSION_UPDATE = SECURE_ENDPOINT + "prnsv/prn03";
  static GET_MESSAGE = SECURE_ENDPOINT + "alsvc/al01";

  /** Bidding Service */
  static GET_ALL_BIDDINGS = SECURE_ENDPOINT + "bngsvc/bng01";
  static CREATE_BIDDING = SECURE_ENDPOINT + "bngsvc/bng02";
  static UPDATE_BIDDING = SECURE_ENDPOINT + "bngsvc/bng03";

  /** Suppler Service */
  static GET_ALL_SUPPLIERS = SECURE_ENDPOINT + "splsvc/spl01";
  static CREATE_SUPPLIER = SECURE_ENDPOINT + "splsvc/spl02";

  /** Remmision Service */
  static GET_REMMISIONS_BY_USER_BRANCH = SECURE_ENDPOINT + "rmsvc/rm01";
  static CREATE_REMMISION = SECURE_ENDPOINT + "rmsvc/rm02";
  static UPDATE_REMMISION = SECURE_ENDPOINT + "rmsvc/rm03";
  static UPDATE_PRODUCT_REMMISION_BY_STOCK = SECURE_ENDPOINT + "rmsvc/rm04";
  static REMMISIONS_GET_WITH_PRODUCTS_REMMISION =
    SECURE_ENDPOINT + "rmsvc/rm05";
  static REMMISIONS_GET_WITH_ALL_PRODUCTS_REMMISION =
    SECURE_ENDPOINT + "rmsvc/rm08";
  static REMMISIONS_ADD_PRODUCT_IN_REMMISION = SECURE_ENDPOINT + "rmsvc/rm09";
  static REMMISIONS_LIST_CHANGE = SECURE_ENDPOINT + "rmsvc/rm10";
  static REMMISIONS_CHANGE = SECURE_ENDPOINT + "rmsvc/rm11";
  static GET_REMISSION_INFO = SECURE_ENDPOINT + "rmsvc/rm12";
  static GET_REMISSION_PRODUCTS = SECURE_ENDPOINT + "rmsvc/rm13";
  static GET_REMISSION_EXISTENT = SECURE_ENDPOINT + "rmsvc/rm14";

  /** Financial Source */
  static GET_ALL_FINANCIAL_SOURCES = SECURE_ENDPOINT + "ffsvc/ff01";
  static GET_ALL_FINANCIAL_SOURCES_AVAILABLE = SECURE_ENDPOINT + "ffsvc/ff02";
  static FISCAL_FUND_LIST_ALL_BY_BRANCH_OFFICE_FOR_TYPE =
    SECURE_ENDPOINT + "ffsvc/ff06";

  /** Products Service */
  static GET_ALL_PRODUCTS = SECURE_ENDPOINT + "prsvc/ps01";
  static GET_ALL_PRODUCTS_AVAILABLE = SECURE_ENDPOINT + "prsvc/ps02";
  static GET_ALL_PRODUCTS_BY_ORDER = SECURE_ENDPOINT + "prsvc/ps07";
  static GET_ALL_PRODUCT_UNIT_MEASUREMENT = SECURE_ENDPOINT + "prsvc/ps09";
  static GET_ALL_PRODUCT_IN_STOCK = SECURE_ENDPOINT + "prsvc/pr11";
  static PRODUCT_STOCK_LIST_ALL_BY_TYPE = SECURE_ENDPOINT + "prsvc/ps12";
  static GET_ALL_PRODUCT_IN_STOCK_EXPIRED = SECURE_ENDPOINT + "prsvc/pr13";

  /** Lots Service */
  static GET_LOTS_BY_BRANCH_FISCAL_PRODUCT = SECURE_ENDPOINT + "prmsvc/prm01";
  static GET_ALL_LOTS = SECURE_ENDPOINT + "prmsvc/prm02";
  static PRODUCTO_REMMISION_LIST_ALL_LOT_BY_TYPE =
    SECURE_ENDPOINT + "prmsvc/prm03";

  /** Goverment Requisitions */
  static GET_ALL_GOVREQUISITIONS = SECURE_ENDPOINT + "rqgsvc/rqg05";

  /** Branch Office */
  static GET_ALL_BRANCH_OFFICE_ACTIVE = SECURE_ENDPOINT + "brofsvc/brof02";
  static BRANCH_OFFICE_LIST_ALL_BY_USER = SECURE_ENDPOINT + "brofsvc/brof07";
  static BRANCH_OFFICE_BY_TYPE_AND_DATE = SECURE_ENDPOINT + "brofsvc/brof12";
  static BRANCH_OFFICE_BY_TYPE = SECURE_ENDPOINT + "brofsvc/brof13";

  /** Login Service */
  static SIGNIN_USER = UNSECURE_ENDPOINT + "lsvc/ls01";
  static SIGNIN_USER_ATTEMPTS = UNSECURE_ENDPOINT + "lsvc/attempts-login";
  static SCHEDULE_REGISTRATION_INPUT_OR_OUTPUT =
    UNSECURE_ENDPOINT + "lsvc/ls02";

  /** Order Service */
  //static ORDER_ADD = SECURE_ENDPOINT + "osvc/or01";
  //static GET_ALL_ORDER_BY_USER = SECURE_ENDPOINT + "osvc/or03";
  //static ORDER_UPDATE = SECURE_ENDPOINT + "osvc/or04";
  //static ORDER_BY_BRANCH = SECURE_ENDPOINT + "osvc/or05";
  static ORDER_DETAIL = SECURE_ENDPOINT + "osvc/or03";
  //static ORDER_DISPESING = SECURE_ENDPOINT + "osvc/or07";
  //static ORDER_HISTORY = SECURE_ENDPOINT + "osvc/or08";
  //static ORDER_LIST_ALL_BY_BRANCH_DESTINATION = SECURE_ENDPOINT + "osvc/or09";
  //static ORDER_DISPENSING = SECURE_ENDPOINT + "osvc/or10";
  static ORDER_CHAGE_SHIPPING = SECURE_ENDPOINT + "osvc/or11";
  static ORDER_ROLL_BACK = SECURE_ENDPOINT + "osvc/or12";

  /** Stock Service */
  static MOVE_STOCK_LOCATION = SECURE_ENDPOINT + "stsvc/stk04";
  static GET_ALL_STOCK_FROM_USER_BRANCH = SECURE_ENDPOINT + "stsvc/stk05";
  static GET_ALL_STOCK_FROM_USER_BRANCH_EXPIRED =
    SECURE_ENDPOINT + "stsvc/stk06";
  static UPDATE_STOCK = SECURE_ENDPOINT + "stsvc/stk07";
  static STOCK_HISTORY_DESTINATION = SECURE_ENDPOINT + "stsvc/stk13";
  static STOCK_HISTORY_ORIGIN = SECURE_ENDPOINT + "stsvc/stk14";
  //static STOCK_DELETE_ORDER_STOCK = SECURE_ENDPOINT + "stsvc/stk10";
  static STOCK_DETAIL = SECURE_ENDPOINT + "stsvc/stk11";
  static STOCK_GET_FULL_DETAIL = SECURE_ENDPOINT + "stsvc/stk16";
  static STOCK_ALL_BY_BRANCH_OFFICE_SIMPLE = SECURE_ENDPOINT + "stsvc/stk11";
  static STOCK_LIST_ALL_EXPIRED = SECURE_ENDPOINT + "stsvc/stk17";
  static STOCK_BY_BRANCH_OFFICE = SECURE_ENDPOINT + "stsvc/stk18";
  static STOCK_UPDATE_QUANTITY = SECURE_ENDPOINT + "stsvc/stk19";
  static STOCK_BY_PRODUCTS_AND_BRANCH_OFFICE = SECURE_ENDPOINT + "stsvc/stk20";
  static STOCK_UPDATE_QUANTITY_TRANSFER = SECURE_ENDPOINT + "stsvc/stk21";
  static STOCK_LIST_ALL_TRANSFERS = SECURE_ENDPOINT + "stsvc/stk22";
  static STOCK_OBSERVATIONS = SECURE_ENDPOINT + "stsvc/stk24";
  static GET_ALL_STOCK_WITH_OBSERVATION_BY_BRANCH =
    SECURE_ENDPOINT + "stsvc/stk25";
  static STOCK_UPDATE_WITH_ORDER_STOCK = SECURE_ENDPOINT + "stsvc/stk26";
  static STOCK_UPDATE_PRESCRIPTION = SECURE_ENDPOINT + "stsvc/stk28";
  static STOCK_BY_PRODUCTS_AND_BRANCH_OFFICE_EXPIRED =
    SECURE_ENDPOINT + "stsvc/stk29";
  static STOCK_NON_CONFORME = SECURE_ENDPOINT + "stsvc/stk30";

  /** Category Service */
  static CATEGORY_LIST_ALL = SECURE_ENDPOINT + "ctgsvc/ctg01";
  static CATEGORY_ADD = SECURE_ENDPOINT + "ctgsvc/ctg02";
  static CATEGORY_UPDATE = SECURE_ENDPOINT + "ctgsvc/ctg03";

  /** Fiscal found Service*/
  static FISCAL_FUND_LIST_ALL = SECURE_ENDPOINT + "ffsvc/ff01";
  static FISCAL_FUND_ADD = SECURE_ENDPOINT + "ffsvc/ff03";
  static FISCAL_FUND_UPDATE = SECURE_ENDPOINT + "ffsvc/ff04";

  /** Products Service */
  static PRODUCT_LIST_ALL = SECURE_ENDPOINT + "prsvc/ps01";
  static PRODUCT_ADD = SECURE_ENDPOINT + "prsvc/pr03";
  static PRODUCT_UPDATE = SECURE_ENDPOINT + "prsvc/pr04";

  /** Supplier Service */
  static SUPPLIER_LIST_ALL = SECURE_ENDPOINT + "splsvc/spl01";
  static SUPPLIER_ADD = SECURE_ENDPOINT + "splsvc/spl02";
  static SUPPLIER_UPDATE = SECURE_ENDPOINT + "splsvc/spl03";
  static SUPPLIER_LIST_BY_FINANCING = SECURE_ENDPOINT + "rqgsvc/rqg09"

  /** Shipments Service */
  static SHIPMENT_LIST_ALL_BY_USER = SECURE_ENDPOINT + "shsvc/shp01";
  static SHIPMENT_FIND_DETAIL = SECURE_ENDPOINT + "shsvc/shp02";
  static SHIPMENTS_GENERATE_TRANSFER = SECURE_ENDPOINT + "shsvc/shp03";
  static ADD_SHIPMENT = SECURE_ENDPOINT + "shsvc/shp04";
  static SHIPMENTS_CHANGE_STATUS = SECURE_ENDPOINT + "shsvc/shp05";
  static SHIPMENT_FIND_DETAIL_BY_ORDER = SECURE_ENDPOINT + "shsvc/shp06";
  static SHIPMENTS_LIST_ALL_BY_STATUS_SHIPPING =
    SECURE_ENDPOINT + "shsvc/shp07";
  static SHIPMENTS_MOVEMENT_HISTORY = SECURE_ENDPOINT + "shsvc/shp08";
  static SHIPMENTS_RETURNS = SECURE_ENDPOINT + "shsvc/shp09";
  static SHIPMENTS_DETAIL_RETURNS = SECURE_ENDPOINT + "shsvc/shp10";
  static SHIPMENTS_WITH_ORDER_TYPE_DECREASE = SECURE_ENDPOINT + "shsvc/shp11";
  static SHIPMENTS_VALIDATE_ORDER = SECURE_ENDPOINT + "shsvc/shp12";
  static SHIPMENTS_FINISH_DECREASE = SECURE_ENDPOINT + "shsvc/shp13";
  static SHIPMENTS_LIST_ALL_BY_TYPE = SECURE_ENDPOINT + "shsvc/shp14";
  static SHIPMENTS_FINISH_STARTING_ORDER = SECURE_ENDPOINT + "shsvc/shp15";
  static SHIPMENT_LIST_ALL_WITH_STATUS = SECURE_ENDPOINT + "shsvc/shp16";
  static SHIPMENT_UPDATE_DESTINATION = SECURE_ENDPOINT + "shsvc/shp17";
  static SHIPMENTS_WITH_ORDER_TYPE_STARTING_ORDER =
    SECURE_ENDPOINT + "shsvc/shp18";
  static PRODUCTO_REMMISION_CHANGE_PRODUCT = SECURE_ENDPOINT + "prmsvc/prm04";

  /** Jurisdiction Service */
  static JURISDICTION_LIST_ALL = SECURE_ENDPOINT + "jrdsvc/jrd01";
  static JURISDICTION_ADD = SECURE_ENDPOINT + "jrdsvc/jrd02";
  static JURISDICTION_UPDATE = SECURE_ENDPOINT + "jrdsvc/jrd03";

  /** BranchOffice Service */
  static BRANCH_OFFICE_LIST_ALL = SECURE_ENDPOINT + "brofsvc/brof08";
  static BRANCH_OFFICE_LIST_ALL_TYPES = SECURE_ENDPOINT + "brofsvc/brof09";
  static BRANCH_OFFICE_ADD = SECURE_ENDPOINT + "brofsvc/brof01";
  static BRANCH_OFFICE_UPDATE = SECURE_ENDPOINT + "brofsvc/brof03";
  static BRANCH_OFFICE_GET = SECURE_ENDPOINT + "brofsvc/brof10";
  static BRANCH_OFFICE_UPDATE_STATUS = SECURE_ENDPOINT + "brofsvc/brof11";

  /** Rol Service */
  static ROL_LIST_ALL = SECURE_ENDPOINT + "rlsvc/rl01";
  static ROL_ADD = SECURE_ENDPOINT + "rlsvc/rl02";
  static ROL_UPDATE = SECURE_ENDPOINT + "rlsvc/rl03";
  static ROL_LIST_ALL_WITH_PERMMISIONS = SECURE_ENDPOINT + "rlsvc/rl05";
  static ROL_LIST_ALL_PERMMISIONS = SECURE_ENDPOINT + "rlsvc/rl09";

 
  static RECEPTION_UPDATE = SECURE_ENDPOINT + "repsvc/";

  /** Reception Service */
  static INCIDENCE_ORDER_ALL_BY_BRANCH_OFFICE =
    SECURE_ENDPOINT + "inorsvc/inor01";
  static INCIDENTE_ORDER_ALL_BY_BRANCH_OFFICE_FROM_ORDER_STOCK =
    SECURE_ENDPOINT + "inorsvc/inor02";
  static INCIDENTE_ORDER_UPDATE_STATUS = SECURE_ENDPOINT + "inorsvc/inor03";
  static INCIDENTE_ORDER_ALL_BY_BRANCH_OFFICE_ORIGIN =
    SECURE_ENDPOINT + "inorsvc/inor04";
  static INCIDENTE_ALL_TYPES = SECURE_ENDPOINT + "inorsvc/inor06";
  static INCIDENTE_ADD = SECURE_ENDPOINT + "inorsvc/inor07";

  /** Return Service */
  static RETURN_LIST_ALL_BY_ORIGIN = SECURE_ENDPOINT + "rtnsvc/rtn01";
  static RETURN_LIST_ALL_BY_DESTINATION = SECURE_ENDPOINT + "rtnsvc/rtn02";
  static RETURN_ADD = SECURE_ENDPOINT + "rtnsvc/rtn03";
  static RETURN_RECEIVE = SECURE_ENDPOINT + "rtnsvc/rtn04";

  /** Doctor Service*/

  static DOCTOR_ADD = SECURE_ENDPOINT + "drsvc/dr01";
  static DOCTOR_UPDATE = SECURE_ENDPOINT + "drsvc/dr02";
  static DOCTOR_LIST_ALL_BY_BRANCH_OFFICE = SECURE_ENDPOINT + "drsvc/dr03";

  /** Medical Prescription Service*/

  static MEDICAL_PRESCRIPTION_LIST_ALL_BY_BRANCH =
    SECURE_ENDPOINT + "mpsvc/mp01";
  static MEDICAL_PRESCRIPTION_ADD = SECURE_ENDPOINT + "mpsvc/mp02";
  static MEDICAL_PRESCRIPTION_BY_PRODUCT = SECURE_ENDPOINT + "mpsvc/mp03";
  static MEDICAL_PRESCRIPTION_ADD_DENIED = SECURE_ENDPOINT + "mpsvc/mp05";
  static MEDICAL_PRESCRIPTION_LIST_ALL_DENIED = SECURE_ENDPOINT + "mpsvc/mp06";
  static MEDICAL_PRESCRIPTION_DELETE_DENIED = SECURE_ENDPOINT + "mpsvc/mp07";
  static MEDICAL_PRESCRIPTION_VALIDATE_ORDER = SECURE_ENDPOINT + "mpsvc/mp08";
  static MEDICAL_PRESCRIPTION_FINISH_PRESCRIPTION =
    SECURE_ENDPOINT + "mpsvc/mp09";
  static MEDICAL_PRESCRIPTION_FIND_BY_ID = SECURE_ENDPOINT + "mpsvc/mp10";
  static MEDICAL_PRESCRIPTION_UPDATE_VALIDATED = SECURE_ENDPOINT + "mpsvc/mp11";
  static MEDICAL_PRESCRIPTION_DOWNLOAD_PRESCRIPTIOM =
    SECURE_ENDPOINT + "mpsvc/mp13";
  static MEDICAL_PRESCRIPTION_SAVE_SURVEY = SECURE_ENDPOINT + "mpsvc/mp14";
  static MEDICAL_PRESCRIPTION_VALIDATE_PRESCRIPTION_KEY =
    SECURE_ENDPOINT + "mpsvc/mp15";
  static MEDICAL_PRESCRIPTION_LIST_ALL_BY_BRANCH_CANCELATIONS =
    SECURE_ENDPOINT + "mpsvc/mp16";

  /** Report Service */
  static REPORT_STARTING_ORDER = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt01";
  static REPORT_PRODUCT_ENTRY = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt02";
  static REPORT_RECEPTION = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt03";
  static REPORT_EXPIRED = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt04";
  static REPORT_EXISTENCE = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt05";
  static REPORT_PRESCRIPTION = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt06";
  static REPORT_DECREASE = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt07";
  static REPORT_STARTING_ORDER_TEMP = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt08";

  static REPORT_PRESCRIPTION_INTRAHOSPITAL = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt10";
  /** Down On Product Service*/

  static DOWN_ADD = SECURE_ENDPOINT + "dcsvc/dc01";
  static DOWN_LIST_ALL_BY_BRANCH_AND_USER = SECURE_ENDPOINT + "dcsvc/dc02";

  /* Excel Service */
  static EXCEL_ORDERS = SECURE_ENDPOINT_REPORTS + "exsvc/ex01";
  static EXCEL_ORDERS_DISPENSING = SECURE_ENDPOINT_REPORTS + "exsvc/ex02";
  static EXCEL_ORDERS_DECREASE = SECURE_ENDPOINT_REPORTS + "exsvc/ex03";
  static EXCEL_SHIPMENTS_TRANSIT = SECURE_ENDPOINT_REPORTS + "exsvc/ex04";
  static EXCEL_ORDERS_HISTORICAL_PICKING = SECURE_ENDPOINT_REPORTS + "exsvc/ex05";
  static EXCEL_ORDERS_MOVEMENT_HISTORY = SECURE_ENDPOINT_REPORTS + "exsvc/ex06";
  static EXCEL_SHIPMENTS = SECURE_ENDPOINT_REPORTS + "exsvc/ex07";
  static EXCEL_ORDER_DETAIL = SECURE_ENDPOINT_REPORTS + "exsvc/ex08";
  static ff = SECURE_ENDPOINT_REPORTS + "exsvc/ex09";
  static EXCEL_BRANCH_OFFICE = SECURE_ENDPOINT_REPORTS + "exsvc/ex09";
  static EXCEL_USERS = SECURE_ENDPOINT_REPORTS + "exsvc/ex10";
  static EXCEL_BIDDINGS = SECURE_ENDPOINT_REPORTS + "exsvc/ex11";
  static EXCEL_WAREHOUSE = SECURE_ENDPOINT_REPORTS + "exsvc/ex12";
  static EXCEL_DOCTOR = SECURE_ENDPOINT_REPORTS + "exsvc/ex13";
  static EXCEL_PRODUCTO = SECURE_ENDPOINT_REPORTS + "exsvc/ex14";
  static EXCEL_JURISDICTIONS = SECURE_ENDPOINT_REPORTS + "exsvc/ex15";
  static EXCEL_FISCAL_FOUND = SECURE_ENDPOINT_REPORTS + "exsvc/ex16";
  static EXCEL_CATEGORY = SECURE_ENDPOINT_REPORTS + "exsvc/ex17";
  static EXCEL_ROLE = SECURE_ENDPOINT_REPORTS + "exsvc/ex18";
  static EXCEL_SUPPLIERS = SECURE_ENDPOINT_REPORTS + "exsvc/ex20";

  static EXCEL_ORDERS_DECREASE_DETAIL = SECURE_ENDPOINT_REPORTS + "exsvc/ex19";
  static EXCEL_REMMISIONS = SECURE_ENDPOINT_REPORTS + "exsvc/ex21";
  static EXCEL_REMMISIONS_ORDERS = SECURE_ENDPOINT_REPORTS + "exsvc/ex22";
  static EXCEL_RECEPTION_PENDINGS = SECURE_ENDPOINT_REPORTS + "exsvc/ex23";
  static EXCEL_RECEPTION_HISTORY = SECURE_ENDPOINT_REPORTS + "exsvc/ex24";
  static EXCEL_INCIDENCE = SECURE_ENDPOINT_REPORTS + "exsvc/ex25";
  static EXCEL_INCIDENCE_IN = SECURE_ENDPOINT_REPORTS + "exsvc/ex26";
  static EXCEL_DASHBOARD_BRANCH_OFFICE = SECURE_ENDPOINT_REPORTS + "exsvc/ex27";
  static EXCEL_STOCK_OBSERVATION = SECURE_ENDPOINT_REPORTS + "exsvc/ex28";
  static EXCEL_EXPIRED_STOCK = SECURE_ENDPOINT_REPORTS + "exsvc/ex29";


  static EXCEL_STOCK_MEDICAL_PRESCRIPTION_PERCENTAGE =
  SECURE_ENDPOINT_REPORTS + "exsvc/ex97";
  static EXCEL_MOVEMENTS_INVENTORY_STOCK = SECURE_ENDPOINT_REPORTS + "exsvc/ex98";
  static EXCEL_REMISSION_SUPPLIER = SECURE_ENDPOINT_REPORTS + "exsvc/ex99";
  static EXCEL_CARDEX = SECURE_ENDPOINT_REPORTS + "exsvc/ex129";
  static EXCEL_MEDICAL_PRESCRIPTION_BY_DATE = SECURE_ENDPOINT_REPORTS + "exsvc/ex101";
  static EXCEL_TRANFER = SECURE_ENDPOINT_REPORTS + "exsvc/ex102";
  static EXCEL_RETURN = SECURE_ENDPOINT_REPORTS + "exsvc/ex103";
  static EXCEL_MOVING_PRODUCTS = SECURE_ENDPOINT_REPORTS + "exsvc/ex104";
  static EXCEL_SHIPMENTS_AND_PRODUCTS = SECURE_ENDPOINT_REPORTS + "exsvc/ex105";
  static EXCEL_RECEPTION = SECURE_ENDPOINT_REPORTS + "exsvc/ex106";
  static EXCEL_MOVEMENTS_INVENTORY_MOVEMENT = SECURE_ENDPOINT_REPORTS + "exsvc/ex108";
  static EXCEL_COSTS_PER_WAREHOUSE = SECURE_ENDPOINT_REPORTS + "exsvc/ex112";
  static EXCEL_ENTRIES_BY_SUPPLIER = SECURE_ENDPOINT_REPORTS + "exsvc/ex41";
  static EXCEL_CHECKIN_AND_CHECKOUT = SECURE_ENDPOINT_REPORTS + "exsvc/ex113";
  static EXCEL_EMPLOYEES = SECURE_ENDPOINT_REPORTS + "exsvc/ex114";
  static EXCEL_DEPARTMENTS = SECURE_ENDPOINT_REPORTS + "exsvc/ex115";
  static EXCEL_POSITIONS = SECURE_ENDPOINT_REPORTS + "exsvc/ex116";
  static EXCEL_REMISSION_SUPPLIER_TRASFER = SECURE_ENDPOINT_REPORTS + "exsvc/ex117";
  static EXCEL_MEDICAL_PRESCRIPTION_INTRAHOSPITAL_BY_DATE =
  SECURE_ENDPOINT_REPORTS + "exsvc/ex118";

  static EXCEL_DISPENSING_CONSILIDATED = SECURE_ENDPOINT_REPORTS + "exsvc/ex120";
  static EXCEL_DISPENSING_CONSILIDATED_NEGATE = SECURE_ENDPOINT_REPORTS + "exsvc/ex121";



  /** DashBoard Service*/

  static DASHBOARD_DETAIL = SECURE_ENDPOINT + "dasvc/da01";
  static DASHBOARD_PERCENTAGE_BY_BRANCH_OFFICE = SECURE_ENDPOINT + "dasvc/da02";
  static DASHBOARD_BRANCHOFFICE_QUANTITIES = SECURE_ENDPOINT + "dasvc/da04";
  static DASHBOARD_BRANCHOFFICE_GENERAL_REPORT = SECURE_ENDPOINT + "dasvc/da06";
  static DASHBOARD_BRANCHOFFICE_SUPPLY_REPORT = SECURE_ENDPOINT + "dasvc/da07";

  /** Province Service */
  static PROVINCE_LIST_ALL = SECURE_ENDPOINT + "prsvc/pr01";

  /** Province Service */
  static USER_BRANCH_OFFICE_LIST_ALL_BY_USER = SECURE_ENDPOINT + "ussvc/ubro05";

  /** Quiz Service */
  static QUIZ_SAVE_ANSWERS = SECURE_ENDPOINT_QUIZ + "sursvc/sur02";

  /*  Employee Service */
  static EMPLOYEE_LIST_ALL = SECURE_ENDPOINT + "emsvc/em01";
  static CREATE_EMPLOYEE = SECURE_ENDPOINT + "emsvc/em02";
  static UPDATE_EMPLOYEE = SECURE_ENDPOINT + "emsvc/em03";

  /*  Department Service */
  static DEPARTMENT_LIST_ALL = SECURE_ENDPOINT + "desvc/de01";
  static CREATE_DEPARTMENT = SECURE_ENDPOINT + "desvc/de02";
  static UPDATE_DEPARTMENT = SECURE_ENDPOINT + "desvc/de03";

  /*  Department Service */
  static POSITION_LIST_ALL = SECURE_ENDPOINT + "posvc/po01";
  static CREATE_POSITION = SECURE_ENDPOINT + "posvc/po02";
  static UPDATE_POSITION = SECURE_ENDPOINT + "posvc/po03";

  /* Pre-request Service */

  /* [NEW] Request Service */
  static GET_REQUEST_ALL = SECURE_ENDPOINT + "shresvc/shre01";
  static SAVE_REQUEST = SECURE_ENDPOINT + "shresvc/shre02";
  static SET_UPDATE_REQUEST_VALIDATION = SECURE_ENDPOINT + "shresvc/shre03";
  static GET_DETAIL_REQUEST = SECURE_ENDPOINT + "shresvc/shre04";
  static SET_STATUS_REQUEST = SECURE_ENDPOINT + "shresvc/shre05";
  static ASIGNATION_REQUEST_USER = SECURE_ENDPOINT + "shresvc/shre07";
  static LIST_ALL_SHIPMENT_REQUEST = SECURE_ENDPOINT + "shresvc/shre08";
  static GET_STATUS_REQUEST_ASSIGNED = SECURE_ENDPOINT + "shresvc/shre09";

  static PREREQUEST_LIST_ALL = SECURE_ENDPOINT + "shresvc/shre01";
  static CREATE_PREREQUEST = SECURE_ENDPOINT + "shresvc/shre02";
  static UPDATE_PREREQUEST = SECURE_ENDPOINT + "shresvc/shre03";
  static DETAIL_PREREQUEST = SECURE_ENDPOINT + "shresvc/shre04";
  static UPDATE_STATUS_PREREQUEST = SECURE_ENDPOINT + "shresvc/shre05";
  static DETAIL_PREREQUEST_PDF = SECURE_ENDPOINT_REPORTS + "rptsvc/rpt09";
  static PRODUCT_LIST_BOARD_DISTRIBUTION = SECURE_ENDPOINT + "dbsvc/db06";

  /* Distribution Board Service */
  static DISTRIBUTION_BOARD_LIST_ALL = SECURE_ENDPOINT + "dbsvc/db01";
  static CREATE_BOARD_DISTRIBUTION = SECURE_ENDPOINT + "dbsvc/db02";
  static UPDATE_BOARD_DISTRIBUTION_INFO = SECURE_ENDPOINT + "dbsvc/db03";
  static UPDATE_BOARD_DISTRIBUTION_PRODUCTS = SECURE_ENDPOINT + "dbpsvc/dbp03";
  static UPDATE_BOARD_DISTRIBUTION_BRANCH_OFFICE =
    SECURE_ENDPOINT + "dbbosvc/dbbo03";

  static DISTRIBUTION_GET_BRANCH_OFFICE = SECURE_ENDPOINT + "dbsvc/db04";
  static DISTRIBUTION_GET_PRODUCTS = SECURE_ENDPOINT + "dbsvc/db05";

  static ORDER_STOCK_DISABLE = SECURE_ENDPOINT + "ossvc/os01";
  static ORDER_STOCK_DETAIL = SECURE_ENDPOINT + "ossvc/os02";

}
