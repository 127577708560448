export const sessionGetter = ( state ) => {
    return state.isAuthenticated;
}

export const getBranchOffice = ( state ) => {
    return state.branchOffice;
}

export const getActionStatus = ( state ) => {
    return state.actionState;
}