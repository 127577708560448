import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/LoginView";
import { UtilFront } from "@/core";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Login", component: LoginView },
  {
    path: "/dashboard",
    name: "DASHBOARD",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/other/views/App.vue"),
  },
  /*-------------- TRACEABILITY ( Trazabilidad ) --------------*/
  {
    path: "/trazabilidad",
    name: "TRACEABILITY",
    component: () => import("@/views/traceability/Traceability.vue"),
  },
  {
    path: "/auditoria/movimientos-entradas-envios",
    name: "AUDIENCE",
    component: () => import("@/views/audit/AuditView.vue"),
  },
  {
    path: "/auditoria/general",
    name: "AUDIENCE",
    component: () => import("@/views/audit/AuditMovementView.vue"),
  },
  /*-------------- Remmisiones ( Entradas ) --------------*/
  {
    path: "/entradas/pedidos",
    name: "REQUISITION",
    component: () => import("@/views/remission/RequisitionView.vue"),
  },
  {
    path: "/entradas/proveedor",
    name: "REMISSION-BY-SUPPLIER",
    component: () => import("@/views/remission/RemissionsView.vue"),
  },
  {
    path: "/entradas/traspaso",
    name: "REMISSION-BY-TRANSFER",
    component: () => import("@/views/remission/RemissionsTransferView.vue"),
  },
  /*-------------- Warehouse ( Almacen ) --------------*/
  {
    path: "/almacen",
    name: "WAREHOUSE",
    component: () => import("@/views/warehouse/WarehouseView.vue"),
  },
  {
    path: "/almacen/productonoconforme",
    name: "INVENTORY-NON-COMPLIANT-PRODUCT",
    component: () => import("@/views/warehouse/WarehouseNonCompliantProductView.vue"),
  },
  {
    path: "/caducados",
    name: "INVENTORY-DETAIL-EXPIRED",
    component: () => import("@/views/warehouse/WarehouseViewExpired.vue"),
  },
  {
    path: "/almacen/ciclico",
    name: "CYCLIC-INVENTORY",
    component: () => import("@/views/warehouse/WarehouseCyclicalInventoryView.vue"),
  },
  /*-------------- ShipmentRequest ( Solicitudes ) --------------*/
  {
    path: "/solicitudes",
    name: "PRE-REQUEST-APPLICATION",
    component: () => import("@/views/shipments-request/RequestView.vue"),
  },
  {
    path: "/solicitudes/validar",
    name: "PRE-REQUEST-VALIDATOR",
    component: () => import("@/views/shipments-request/RequestValidate.vue"),
  },
  {
    path: "/solicitudes/autorizar",
    name: "PRE-REQUEST-AUTHORIZER",
    component: () => import("@/views/shipments-request/RequestAuthorization.vue"),
  },
  /*-------------- Orders ( Salidas ) --------------*/
  {
    path: "/salidas",
    name: "STARTING-ORDER",
    component: () => import("@/views/startingorder/StartingOrderView.vue"),
  },
  {
    path: "/salidas/motivo", // Salidas con motivo
    name: "STARTING-ORDER-BY-REASON",
    component: () => import("@/views/startingorder/DecreaseView.vue"),
  },
  /*-------------- Picking ( Recolección ) --------------*/
  {
    path: "/picking",
    name: "PICKING-DETAIL",
    component: () => import("@/views/picking/PickingView.vue"),
  },
  {
    path: "/picking/movimiento",
    name: "PICKING-MOVEMENTS",
    component: () => import("@/views/picking/PickingMovementsView.vue"),
  },
  /*-------------- Shipments ( Envios ) --------------*/
  {
    path: "/envios",
    name: "SHIPMENT-DETAIL",
    component: () => import("@/views/shipments/ShipmentsView.vue"),
  },
  {
    path: "/envios/historico",
    name: "HISTORY-OF-SHIPMENTS",
    component: () => import("@/views/shipments/ShipmentsHistoryView.vue"),
  },

  /*-------------- Reception ( Recepcion ) --------------*/

  {
    name: "PENDING",
    path: "/recepcion/pendientes",
    component: () => import("@/views/reception/ReceptionPendingView.vue"),
  },
  {
    name: "HISTORICAL",
    path: "/recepcion/historico",
    component: () => import("@/views/reception/ReceptionHistoryView.vue"),
  },

  /*-------------- Incidence ( Incidencias ) --------------*/
  {
    name: "STARTERS",
    path: "/incidencia/entrantes",
    component: () => import("@/views/incidence/IncidenceOrderInView.vue"),
  },
  {
    name: "INCIDENTS",
    path: "/incidencia/realizadas",
    component: () => import("@/views/incidence/IncidenceOrderOutView.vue"),
  },

  /*-------------- Dispensing ( Dispensacion ) --------------*/
  {
    path: "/configuracion/doctores",
    name: "DOCTORS",
    component: () => import("@/views/dispensing/DoctorView.vue"),
  },
  {
    path: "/dispensacion",
    name: "DISPENSING",
    component: () => import("@/views/dispensing/DispensingView.vue"),
  },
  {
    path: "/dispensacion/intrahospitalaria",
    name: "PRESCRIPTIONS-INTRAHOSPITAL-DISPENSING",
    component: () => import("@/views/dispensing/InHospitalDispensing.vue"),
  },
  {
    name: "STAFF",
    path: "/configuracion/empleados",
    component: () => import("@/views/dispensing/EmployeeView.vue"),
  },
  /*-------------- Cancellation ( Cancelación ) --------------*/
  {
    name: "CANCELLATIONS",
    path: "/cancelaciones",
    component: () => import("@/views/cancellation/CancellationView.vue"),
  },
  /*-------------- Administration ( Administracion ) --------------*/
  {
    name: "USERS",
    path: "/administracion/usuarios",
    component: () => import("@/views/user/UserView.vue"),
  },
  {
    name: "ROLES",
    path: "/administracion/roles",
    component: () => import("@/views/user/RolView.vue"),
  },

  /*-------------- Configuration ( Configuracion ) --------------*/
  {
    path: "/catalogos/categorias",
    name: "CATEGORIES",
    component: () => import("@/views/catalogs/CategoryView.vue"),
  },
  {
    path: "/catalogos/fuentesFinanciamiento",
    name: "FINANCING",
    component: () => import("@/views/catalogs/FiscalFoundView.vue"),
  },
  {
    path: "/catalogos/productos",
    name: "PRODUCTS",
    component: () => import("@/views/catalogs/ProductsView.vue"),
  },
  {
    path: "/catalogos/proveedores",
    name: "SUPPLIERS",
    component: () => import("@/views/catalogs/SupplierView.vue"),
  },
  // {
  //   path: "/catalogos/jurisdicciones",
  //   name: "JURISDICTIONS",
  //   component: () => import("@/views/catalogs/JurisdictionView.vue"),
  // },
  {
    name: "BRANCH-OFFICES",
    path: "/catalogos/sucursales",
    component: () => import("@/views/catalogs/BranchOfficeView.vue"),
  },
  {
    name: "DISTRIBUTION-BOARD",
    path: "/catalogos/cuadroDistribucion",
    component: () => import("@/views/catalogs/DistributionBoardView.vue"),
  },
  {
    name: "DEPARTMENTS",
    path: "/catalogos/departamentos",
    component: () => import("@/views/catalogs/DepartmentView.vue"),
  },
  {
    name: "POSITIONS",
    path: "/catalogos/cargo",
    component: () => import("@/views/catalogs/PositionView.vue"),
  },
  /*-------------- Configuration ( Configuración ) --------------*/
  {
    name: "CONFIGURATION",
    path: "/configuracion/configuracionTableroControl",
    component: () => import("@/views/configuration/ConfigurationDashboardView.vue"),
  },
  {
    name: "CONFIGURATION-ABASTO",
    path: "/configuracion/tableroControlAbasto",
    component: () => import("@/views/configuration/DashboardAbastoView.vue"),
  },

  /*-------------- Health Emergency ( Emergencia Sanitaria ) --------------*/
  {
    name: "NON-COMPLIANT-PRODUCT",
    path: "/productonoconforme/:action",
    component: () => import("@/views/non-compliant-product/NonCompliantProductView.vue"),
  },
  
  /*-------------- General ( General ) --------------*/
  {
    path: "/perfil",
    name: "PROFILE-MANAGEMENT",
    component: () => import("@/views/ProfileView.vue"),
  },
  {
    path: "*",
    redirect: "/dashboard",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let vm = new Vue();
  let today = new Date();
  let isAuthenticated = vm.$session.exists();
  
  /* if (isAuthenticated && vm.$session.get("expired") == undefined) {
    let expired = (today.getHours()+6) + ":" + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());
    vm.$session.set("expired", expired);
    store.commit("auth/UPDATE_EXPIRED_SESSION", {
      isExpired: false,
      expiredTime: expired
    });
  }
  if (isAuthenticated && vm.$session.get("expired") != undefined) {
    store.commit("auth/UPDATE_EXPIRED_SESSION", {
      isExpired: false,
      expiredTime: vm.$session.get("expired")
    });
    if (vm.$session.get("expired") <= (today.getHours()) + ":" + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())) {
      store.commit("auth/UPDATE_EXPIRED_SESSION", {
        isExpired: true,
        expiredTime: ""
      });
    }
  } */
  if (!isAuthenticated && to.name == "Login") {
    store.commit("auth/SESSION_UPDATE", isAuthenticated);
    next();
  } else if (!isAuthenticated && to.name != "Login") {
    store.commit("auth/SESSION_UPDATE", isAuthenticated);
    next({ name: "Login" });
  } else if (isAuthenticated && to.name == "DASHBOARD") {
    store.commit("auth/SESSION_UPDATE", isAuthenticated);
    next();
  } 
  else if (isAuthenticated) {
    if (to.name != undefined) {
      store.commit("auth/SESSION_UPDATE", isAuthenticated);
      let dataUser = UtilFront.getDataUser();
      let withPermission = dataUser.permissions.filter((permission) => permission == to.name).length == 1;
      if (withPermission) {
        next();
      } else {
        next({ name: "DASHBOARD" });
      }
    } else {
      next();
    }
  }
});

export default router;
