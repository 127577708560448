/**
 * @author Yair Alvarez | Jesus Teran
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { SECURE_ENDPOINT, UNSECURE_ENDPOINT } from "@/core/AppConfiguration";
import { headers, headersToken } from "@/core/Headers";

export const instance = axios.create({
  baseURL: SECURE_ENDPOINT,
  headers: headers(),
});

export const un_instance = axios.create({
  baseURL: UNSECURE_ENDPOINT,
  headers: headersToken(),
});

instance.interceptors.response.use(
  (response) => {
    const status = response.status;
    const data = response.data;
    const code_error = response?.data;
    switch (status) {
      // bad request
     case 202: {
      return Promise.reject(code_error ? { message:code_error } :{ message: "bad request 202"} );
      }
      default: {
        return response;
      }
    }
  },
  (error) => {
    const status = error.response ? error.response.status : 500;
    const code_error = error.response?.data;
    switch (status) {
      // bad request
      case 400: {
        return Promise.reject(code_error ? { message:code_error } :{ message: "bad request 400"} );
      }
      // authentication
      case 401: {
        return Promise.reject(code_error ? { message:code_error } :{ message: "authentication 401" });
      }
      // forbidden (permission issues)
      case 403: {
        return Promise.reject(code_error ? { message:code_error } :{ message: "forbidden (permission issues) 403" });
      }
      // not found
      case 404: {
        return Promise.reject(code_error ? { message:code_error } :{ message: "not found 404" });
      }
      // Method Not Allowed
      case 405: {
        return Promise.reject(code_error ? { message:code_error } :{ message: "Method Not Allowed 405" });
      }
      // conflict
      case 409: {
        return Promise.reject(code_error ? { message:code_error }:{ message: "conflict 409" });
      }
      // not available
      case 410: {
        return Promise.reject(code_error ? { message:code_error }:{ message: "not available 410" });
      }
      // unsupported media type
      case 415: {
        return Promise.reject(code_error ? { message:code_error }:{ message: "unsupported media type" });
      }
      // unprocessable
      case 422: {
        return Promise.reject(code_error ? { message:code_error }:{ message: "unprocessable 422" });
      }
      case 500: {
        return Promise.reject(code_error ? { message:code_error }:{ message: "server 500" });
      }
      // unexpected errors
      default: {
        return Promise.reject({ message: "unexpected errors" });
      }
    }
  }
);

un_instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : 500;
    switch (status) {
      // authentication
      case 401: {
        return Promise.reject(error.response.statusText);
      }
      // forbidden (permission issues)
      case 403: {
        return Promise.reject(error.response.statusText);
      }
      // not found
      case 404: {
        return Promise.reject(error.response.statusText);
      }
      // unexpected errors
      default: {
        return Promise.reject(error);
      }
    }
  }
);
