import { ServicePath, UtilFront } from "@/core";
import axios from "axios";

export const getListProducts = async ({ commit, val }) => {
  await axios.post(ServicePath.GET_ALL_PRODUCTS).then((response) => {
    let productFormList = [];
    let { data } = response.data.data;
    if (data) {
      for (var [index, value] of Object.entries(data)) {
        index;
        let productItem = {
          text:
            value.productKey +
            " | " +
            value.description +
            " | Presentación: " +
            value.unitMeasurementDescription,
          value: value.idProduct,
        };
        productFormList.push(productItem);
      }

      let listProduct = {
        productFormList: productFormList,
        productServerList: data,
      };
      commit("GET_PRODUCTS", listProduct);
    }
  });
};

export const getRemissionExistent = async ({ commit }, payload) => {
  let loading = UtilFront.getLoadding();
  await axios
    .post(ServicePath.GET_REMISSION_EXISTENT, {
      remissionkey: payload,
    })
    .then((response) => {
      let { data, success } = response.data.data;
      if (success) {
        commit("SET_REMISSION_EXISTENT", data);
      } else {
        commit("SET_REMISSION_EXISTENT", []);
      }
    })
    .catch((error) => {
      console.log(error);
      commit("SET_REMISSION_EXISTENT", []);
    })
    .finally(() => {
      loading.hide();
    });
};
