import state from "./state";
import * as mutations from "./mutations";
import * as getter from "./getter";
import * as actions from "./actions";

const global = {
  namespaced: true,
  state,
  mutations,
  getter,
  actions,
};

export default global;
