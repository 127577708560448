import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-outline-success mr-2",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

export const SwalConfirm = swalWithBootstrapButtons.mixin({
  icon: "question",
  title: "¿Estás seguro?",
  text: "",
  showCancelButton: true,
  cancelButtonText: '<i class="feather icon-alert-circle mr-1 mr-1"></i> Cancelar',
  confirmButtonText: '<i class="feather icon-check mr-1 mr-1"></i> Aceptar',
  allowOutsideClick: false,
  allowEscapeKey: false,
});

export const SwalDelete = swalWithBootstrapButtons.mixin({
  icon: "warning",
  title: "¿Estás seguro?",
  text: "",
  showCancelButton: true,
  cancelButtonText: '<i class="feather icon-alert-circle mr-1 mr-1"></i> Cancelar',
  confirmButtonText: '<i class="feather icon-check mr-1 mr-1"></i> Aceptar',
  allowOutsideClick: false,
  allowEscapeKey: false,
});

export const SwalInfo = swalWithBootstrapButtons.mixin({
  icon: "info",
  title: "",
  text: "",
  confirmButtonText: '<i class="feather icon-check mr-1 mr-1"></i> Aceptar',
});

export default {
  SwalConfirm,
  SwalInfo,
  SwalDelete,
};
