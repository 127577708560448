/**
 * @author Jesus Teran Cervantes
 * @company Erillam Healthcare
 * @version 2.0
 */

import { instance } from "@/core/ApiConfig.js";

const USER_GET_ALL = "users-branch-offices";
const USER_CREATE = "users-branch-offices/create";
const USER_UPDATE_INFORMATION_PERSONAL = "users-branch-offices";
const USER_UPDATE_STATUS = "users-branch-offices/status";
const USER_RESET_PASSWORD = "users-branch-offices/reset-password";
const USER_GET_DETAIL = "users-branch-offices/detail";
const USER_UPDATE_BRANCH_OFFICE = "users-branch-offices/update-branch-offices";
const USER_GET_INFO_UPDATE = "users-branch-offices/info";

const USER_UPDATE_EMAIL = "users-branch-offices/us08";
const USER_UPDATE_PASSWORD = "users-branch-offices/us07";

export default class UserRequest {
  static getAllUser = (pages) => {
    return instance.post(USER_GET_ALL, pages);
  };

  static addUser = (data) => {
    return instance.post(USER_CREATE, data);
  };

  static updateInformationPersonal = (data) => {
    return instance.put(USER_UPDATE_INFORMATION_PERSONAL, data);
  };

  static updateUserBranchOffice = (data) => {
    return instance.put(USER_UPDATE_BRANCH_OFFICE, data);
  };

  static resetPasswordUser = (data) => {
    return instance.put(USER_RESET_PASSWORD, data);
  };

  static detailUser = (data) => {
    return instance.post(USER_GET_DETAIL, data);
  };

  static detailUserUpdate = (data) => {
    return instance.post(USER_GET_INFO_UPDATE, data);
  };

  static updateStatus = (data) => {
    return instance.put(USER_UPDATE_STATUS, data);
  };

  static updatePassword = (data) => {
    return instance.put(USER_UPDATE_PASSWORD, data);
  };

  static updateEmail = (data) => {
    return instance.put(USER_UPDATE_EMAIL, data);
  };
}
