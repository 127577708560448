<template>
  <div>
    <textarea v-model="content_js" :id="id_js"> </textarea>
  </div>
</template>

<script>
import * as CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/javascript/javascript.js";
export default {
  props: {
    content: "",
    refres: "",
    taman: {
      default: 100,
    },
    show_code: {
      default: false,
    },
    id: {
      default: "editor",
    },
    id_js: {
      default: "js_editor",
    },
    show_js: {
      default: false,
    },
    content_js: {
      default: "",
    },
  },
  data() {
    return {
      js: "",
      html: "",
    };
  },
  mounted() {
    this.js = CodeMirror.fromTextArea(document.getElementById(this.id_js), {
      lineNumbers: false,
      theme: "material",
      mode: "javascript",
      json: true,
      tabMode: "shift",
      autoRefresh: true,
    });
  },
  methods: {
    refresh() {
      // use the object for refresh CodeMirror
      this.js.setSize("100%", this.taman);
      this.js.setValue(this.content_js);
      var that = this;
      setTimeout(function() {
        that.js.refresh();
      }, 2);
    },
  },
  watch: {
    content_js() {
      this.refresh();
    },
  },
};
</script>

<style>
.CodeMirror {
  border-radius: 10px;
  padding: 20px;
}

</style>
